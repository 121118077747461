import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { Provider } from "react-redux"
import configureStore from "./services/store"
import { BrowserRouter } from "react-router-dom"
import reportWebVitals from "./reportWebVitals"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/browser"
import { useEffect } from "react"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"

// This configures the Sentry SDK as soon as the app starts
// will only be initialized in production, production env includes all branches where the app gets deployed
process.env.NODE_ENV === "production" && Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_BRANCH_NAME,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    // if (event.exception) {
    //   Sentry.showReportDialog({ eventId: event.event_id, lang: "fr" })
    // }
    return event
  },
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={configureStore()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

reportWebVitals()
