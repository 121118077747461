import { call, takeEvery } from "redux-saga/effects"
import {
  create_non_staffe_action_done,
  liste_team_disponible_action_done,
  update_non_staffe_action_done,
  user_disponible_action_done
} from "../actions/nonStaffe"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, patch, post } from "../services/request"


// liste_team_disponible
function* liste_team_disponible_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_TEAM_DISPONIBLE}`,
    get,
    null,
    liste_team_disponible_action_done,
  )
}

export function* watch_liste_team_disponible_saga() {
  yield takeEvery("LISTE_TEAM_DISPONIBLE", liste_team_disponible_saga)
}

// liste_team_disponible
function* user_disponible_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_TEAM_DISPONIBLE}?${action.payload.user ? "&user=" + action.payload.user : ""}`,
    get,
    null,
    user_disponible_action_done,
  )
}

export function* watch_user_disponible_saga() {
  yield takeEvery("USER_DISPONIBLE", user_disponible_saga)
}


// create_non_staffe
function* create_non_staffe_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_NON_STAFFE}`,
    post,
    action.payload,
    create_non_staffe_action_done,
  )
}

export function* watch_create_non_staffe_saga() {
  yield takeEvery("CREATE_NON_STAFFE", create_non_staffe_saga)
}

// update_non_staffe
function* update_non_staffe_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATE_NON_STAFFE}${action.payload.id}/`,
    patch,
    action.payload.data,
    update_non_staffe_action_done,
  )
}

export function* watch_update_non_staffe_saga() {
  yield takeEvery("UPDATE_NON_STAFFE", update_non_staffe_saga)
}
