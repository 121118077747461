// Login
export function login_action(payload) {
  return {
    type: "LOGIN",
    payload,
  }
}

export function login_action_done(request) {
  return {
    type: "LOGIN_DONE",
    payload: request,
  }
}

// List Permission
export function liste_permission_action(payload) {
  return {
    type: "LISTE_PERMISSION",
    payload,
  }
}

export function liste_permission_action_done(request) {
  return {
    type: "LISTE_PERMISSION_DONE",
    payload: request,
  }
}

// Info User
export function user_info_action(payload) {
  return {
    type: "USER_INFO",
    payload,
  }
}

export function user_info_action_done(request) {
  return {
    type: "USER_INFO_DONE",
    payload: request,
  }
}

// Edit User
export function edit_user_action(payload) {
  return {
    type: "EDIT_USER",
    payload,
  }
}

export function edit_user_action_done(request) {
  return {
    type: "EDIT_USER_DONE",
    payload: request,
  }
}

// Liste users trombinoscope
export function liste_users_trombino_action(payload) {
  return {
    type: "LISTE_USERS_TROMBINO",
    payload,
  }
}

export function liste_users_trombino_action_done(request) {
  return {
    type: "LISTE_USERS_TROMBINO_DONE",
    payload: request,
  }
}

// Liste teams
export function liste_team_action(payload) {
  return {
    type: "LISTE_TEAMS",
    payload,
  }
}

export function liste_team_action_done(request) {
  return {
    type: "LISTE_TEAMS_DONE",
    payload: request,
  }
}

// Liste teams retard
export function liste_team_retard_action(payload) {
  return {
    type: "LISTE_TEAMS_RETARD",
    payload,
  }
}

export function liste_team_retard_action_done(request) {
  return {
    type: "LISTE_TEAMS_RETARD_DONE",
    payload: request,
  }
}

// Liste user times
export function liste_user_times_action(payload) {
  return {
    type: "LISTE_USER_TIMES",
    payload,
  }
}

export function liste_user_times_action_done(request) {
  return {
    type: "LISTE_USER_TIMES_DONE",
    payload: request,
  }
}

// Liste user times download
export function liste_user_times_download_action(payload) {
  return {
    type: "LISTE_USER_TIMES_DOWNLOAD",
    payload,
  }
}

export function liste_user_times_download_action_done(request) {
  return {
    type: "LISTE_USER_TIMES_DOWNLOAD_DONE",
    payload: request,
  }
}

// User Stat data controller
export function user_date_controller_action(payload) {
  return {
    type: "USER_DATE_CONTROLLER",
    payload,
  }
}

export function user_date_controller_action_done(request) {
  return {
    type: "USER_DATE_CONTROLLER_DONE",
    payload: request,
  }
}

// User Stat
export function user_stat_action(payload) {
  return {
    type: "USER_STAT",
    payload,
  }
}

export function user_stat_action_done(request) {
  return {
    type: "USER_STAT_DONE",
    payload: request,
  }
}
