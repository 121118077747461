import { createTheme } from "@mui/material"

const theme = createTheme({
  spacing: 8,
  typography: {
    fontFamily: ["Nunito", "Roboto", "Arial", "sans-serif"].join(","),
    htmlFontSize: 16,
    h1: {
      fontSize: "4rem",
      fontWeight: 600
    },
    h2: {
      fontSize: "3.5rem",
      fontWeight: 600
    },
    h3: {
      fontSize: "3rem",
      fontWeight: 600
    },
    h4: {
      fontSize: "2.5rem",
      fontWeight: 600
    },
    h5: {
      fontSize: "2rem",
      fontWeight: 600
    },
    h6: {
      fontSize: "1.5rem",
      fontWeight: 600
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 300
    },
    body2: {
      fontSize: "0.8rem",
      fontWeight: 300
    },
    subtitle1: {
      fontSize: "1.2rem",
      fontWeight: 400
    },
    subtitle2: {
      fontSize: "0.8rem",
      fontWeight: 500
    },
    button: {
      textTransform: "uppercase"
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#1E2867"
    },
    secondary: {
      main: "#57a08f"
    },
    info: {
      main: "#2196f3"
    },
    success: {
      main: "#3ca645"
    },
    warning: {
      main: "#ff9800"
    },
    error: {
      main: "#f44336"
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        color: "primary",
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: "0.7rem",
          textTransform: "uppercase",
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "separate",
          borderSpacing: "0 1rem",
          "& thead th": {
            fontWeight: 700,
            fontSize: "0.8rem",
            color: "#1E2867",
            backgroundColor: "#e5e5f2",
            borderTop: "1px solid #d3d3d3",
            borderBottom: "1px solid #d3d3d3",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            "&:first-of-type": {
              borderLeft: "1px solid #d3d3d3",
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            },
            "&:last-child": {
              borderRight: "1px solid #d3d3d3",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            },
          },
          "& tbody tr": {
            backgroundColor: "#fff",
            "&:nth-of-type(odd)": {
              backgroundColor: "#f6f6f6",
            },
          },
          "& tbody td": {
            fontSize: "0.8rem",
            color: "#000000",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            borderTop: "1px solid #E7E9EF",
            borderBottom: "1px solid #E7E9EF",
            "&:first-of-type": {
              borderLeft: "1px solid #E7E9EF",
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            },
            "&:last-child": {
              borderRight: "1px solid #E7E9EF",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            },
          },
        },
      }
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
      }
    },
    // Exemple de styleOverrides pour le DatePicker
    MuiDatePicker: {
      styleOverrides: {
        root: {
          color: "red",
        },
        day: {
          backgroundColor: "blue",
          "&:hover": {
            backgroundColor: "lightblue",
          },
        },
        month: {
          fontSize: "20px",
        },
        year: {
          fontWeight: "bold",
        },
      },
    },
  },
})

export default theme
