export const initialState = {
  Liste_fournisseur: [],
  Liste_fournisseur_loading: false,
  fournisseur_info: {},
  fournisseur_added: {},
  fournisseur_files_added: {},
  fournisseur_contacts_added: {},
  fournisseur_updated: {},
  List_facture: [],
  categorie_fournisseur: [],
  categorie_fournisseur_loading: false,
  sous_categorie_fournisseur: [],
  sous_categorie_fournisseur_loading: false,
  files_fournisseur: {},
  files_fournisseur_loading: false,
  custom_values_fournisseur: [],
  custom_values_fournisseur_loading: false,
  contact_fournisseur: [],
  contact_fournisseur_loading: false,
  custom_values: [],
  liste_facture_fournisseur: [],
  add_facture_fournisseur: {},
  update_facture_fournisseur: {},
  statut_facture_fournisseur: [],
  liste_facturation_fournisseur: [],
  liste_allocations_offre_facture_fournisseur: [],
  liste_allocation_offre_facture: [],
}
const fournisseur = (state = initialState, action) => {
  switch (action.type) {
  case "LIST_FACTURE_DONE": {
    return {
      ...state,
      List_facture: action.payload,
    }
  }
  case "LISTE_FOURNISSEUR_DONE": {
    return {
      ...state,
      Liste_fournisseur: action.payload,
      Liste_fournisseur_loading: false,
    }
  }
  case "LISTE_FOURNISSEUR": {
    return {
      ...state,
      Liste_fournisseur_loading: true,
    }
  }
  case "INFO_FOURNISSEUR_DONE": {
    return {
      ...state,
      fournisseur_info: action.payload
    }
  }
  case "ADD_FOURNISSEUR": {
    return {
      ...state,
      fournisseur_added: {}
    }
  }
  case "ADD_FOURNISSEUR_DONE": {
    return {
      ...state,
      fournisseur_added: action.payload
    }
  }
  case "UPDATE_FOURNISSEUR_DONE": {
    return {
      ...state,
      fournisseur_updated: action.payload
    }
  }
  case "CATEGORIE_FOURNISSEUR_DONE": {
    return {
      ...state,
      categorie_fournisseur: action.payload,
      categorie_fournisseur_loading: false
    }
  }
  case "CATEGORIE_FOURNISSEUR": {
    return {
      ...state,
      categorie_fournisseur_loading: true
    }
  }
  case "CONTACT_FOURNISSEUR_DONE": {
    return {
      ...state,
      contact_fournisseur: action.payload,
      contact_fournisseur_loading: false
    }
  }
  case "CONTACT_FOURNISSEUR": {
    return {
      ...state,
      contact_fournisseur_loading: true
    }
  }
  case "SOUS_CATEGORIE_FOURNISSEUR_DONE": {
    return {
      ...state,
      sous_categorie_fournisseur: action.payload,
      sous_categorie_fournisseur_loading: false
    }
  }
  case "SOUS_CATEGORIE_FOURNISSEUR": {
    return {
      ...state,
      sous_categorie_fournisseur_loading: true
    }
  }
  case "FILES_FOURNISSEUR_DONE": {
    return {
      ...state,
      files_fournisseur: action.payload,
      files_fournisseur_loading: false
    }
  }
  case "ADD_FILES_FOURNISSEUR_DONE": {
    return {
      ...state,
      fournisseur_files_added: action.payload,
    }
  }
  case "ADD_CONTACTS_FOURNISSEUR_DONE": {
    return {
      ...state,
      fournisseur_contacts_added: action.payload,
    }
  }
  case "FILES_FOURNISSEUR": {
    return {
      ...state,
      files_fournisseur_loading: true
    }
  }
  case "CUSTOM_VALUES_FOURNISSEUR_DONE": {
    return {
      ...state,
      custom_values_fournisseur: action.payload,
      custom_values_fournisseur_loading: false
    }
  }
  case "CUSTOM_VALUES_FOURNISSEUR": {
    return {
      ...state,
      custom_values_fournisseur_loading: true
    }
  }
  case "CUSTOM_VALUES_DONE": {
    return {
      ...state,
      custom_values: action.payload
    }
  }
  case "LISTE_FACTURE_FOURNISSEUR_DONE":
    return {
      ...state,
      liste_facture_fournisseur: action.payload,
    }
  case "ADD_FACTURE_FOURNISSEUR_DONE":
    return {
      ...state,
      add_facture_fournisseur: action.payload,
    }
  case "UPDATE_FACTURE_FOURNISSEUR_DONE":
    return {
      ...state,
      update_facture_fournisseur: action.payload,
    }
  case "STATUT_FACTURE_FOURNISSEUR_DONE":
    return {
      ...state,
      statut_facture_fournisseur: action.payload,
    }
  case "REVEBUE_LIST_FOURNISSEUR_DONE":
    return {
      ...state,
      statut_facture_fournisseur: action.payload,
    }
  case "ALLOCATION_OFFRE_FOURNISSEUR_DONE":
    return {
      ...state,
      liste_allocations_offre_facture_fournisseur: action.payload,
    }
  case "ALLOCATION_OFFRE_FACTURE_FOURNISSEUR_DONE":
    return {
      ...state,
      liste_allocation_offre_facture: action.payload,
    }
  case "LISTE_FOURNISSEUR_ALL_DONE":
    return {
      ...state,
      Liste_fournisseur: action.payload,
    }
  default:
    return state
  }
}

export default fournisseur
