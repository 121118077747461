export const initialState = {
  List_team_disponible: [],
  user_disponible: [],
  non_staffe_created: [],
  non_staffe_updated: [],
}
const nonStaffe = (state = initialState, action) => {
  switch (action.type) {
  case "LISTE_TEAM_DISPONIBLE_DONE": {
    return {
      ...state,
      List_team_disponible: action.payload
    }
  }
  case "USER_DISPONIBLE_DONE": {
    return {
      ...state,
      user_disponible: action.payload
    }
  }
  case "CREATE_NON_STAFFE_DONE": {
    return {
      ...state,
      non_staffe_created: action.payload
    }
  }
  case "UPDATE_NON_STAFFE_DONE": {
    return {
      ...state,
      non_staffe_updated: action.payload
    }
  }
  default:
    return state
  }
}

export default nonStaffe
