// liste_rapport_user_ticker
export function liste_rapport_user_ticker_action(payload) {
  return {
    type: "LISTE_RAPPORT_USER_TICKER",
    payload,
  }
}

export function liste_rapport_user_ticker_action_done(request) {
  return {
    type: "LISTE_RAPPORT_USER_TICKER_DONE",
    payload: request,
  }
}

// liste_explication
export function liste_explication_action(payload) {
  return {
    type: "LISTE_EXPLICATION",
    payload,
  }
}

export function liste_explication_action_done(request) {
  return {
    type: "LISTE_EXPLICATION_DONE",
    payload: request,
  }
}

// liste_explication_author
export function liste_explication_author_action(payload) {
  return {
    type: "LISTE_EXPLICATION_AUTHOR",
    payload,
  }
}

export function liste_explication_author_action_done(request) {
  return {
    type: "LISTE_EXPLICATION_AUTHOR_DONE",
    payload: request,
  }
}

// create_demande_explication
export function create_demande_explication_action(payload) {
  return {
    type: "CREATE_DEMANDE_EXPLICATION",
    payload,
  }
}

export function create_demande_explication_action_done(request) {
  return {
    type: "CREATE_DEMANDE_EXPLICATION_DONE",
    payload: request,
  }
}

// update_demande_explication
export function update_demande_explication_action(payload) {
  return {
    type: "UPDATE_DEMANDE_EXPLICATION",
    payload,
  }
}

export function update_demande_explication_action_done(request) {
  return {
    type: "UPDATE_DEMANDE_EXPLICATION_DONE",
    payload: request,
  }
}

// create_reponse_explication
export function create_reponse_explication_action(payload) {
  return {
    type: "CREATE_REPONSE_EXPLICATION",
    payload,
  }
}

export function create_reponse_explication_action_done(request) {
  return {
    type: "CREATE_REPONSE_EXPLICATION_DONE",
    payload: request,
  }
}
