// Liste tickers
export function liste_tickers_action(payload) {
  return {
    type: "LISTE_TICKER",
    payload,
  }
}

export function liste_tickers_action_done(request) {
  return {
    type: "LISTE_TICKER_DONE",
    payload: request,
  }
}

// mes_tickers_15j_stat
export function mes_tickers_15j_stat_action(payload) {
  return {
    type: "MES_TICKER_15J_STAT",
    payload,
  }
}

export function mes_tickers_15j_stat_action_done(request) {
  return {
    type: "MES_TICKER_15J_STAT_DONE",
    payload: request,
  }
}

// mes_tickers_15j
export function mes_tickers_15j_action(payload) {
  return {
    type: "MES_TICKER_15J",
    payload,
  }
}

export function mes_tickers_15j_action_done(request) {
  return {
    type: "MES_TICKER_15J_DONE",
    payload: request,
  }
}

// mes_tickers_1mois
export function mes_tickers_1mois_action(payload) {
  return {
    type: "MES_TICKER_1MOIS",
    payload,
  }
}

export function mes_tickers_1mois_action_done(request) {
  return {
    type: "MES_TICKER_1MOIS_DONE",
    payload: request,
  }
}

// mes_tickers_sans_activite
export function mes_tickers_sans_activite_action(payload) {
  return {
    type: "MES_TICKER_SANS_ACTIVITE",
    payload,
  }
}

export function mes_tickers_sans_activite_action_done(request) {
  return {
    type: "MES_TICKER_SANS_ACTIVITE_DONE",
    payload: request,
  }
}

// mes_tickers_passif
export function mes_tickers_passif_action(payload) {
  return {
    type: "MES_TICKER_PASSIF",
    payload,
  }
}

export function mes_tickers_passif_action_done(request) {
  return {
    type: "MES_TICKER_PASSIF_DONE",
    payload: request,
  }
}

// mes_tickers_marques
export function mes_tickers_marques_action(payload) {
  return {
    type: "MES_TICKER_MARQUE",
    payload,
  }
}

export function mes_tickers_marques_action_done(request) {
  return {
    type: "MES_TICKER_MARQUE_DONE",
    payload: request,
  }
}

// Liste tickers super user
export function liste_tickers_super_user_action(payload) {
  return {
    type: "LISTE_TICKER_SUPER_USER",
    payload,
  }
}

export function liste_tickers_super_user_action_done(request) {
  return {
    type: "LISTE_TICKER_SUPER_USER_DONE",
    payload: request,
  }
}

// Liste tickers search
export function liste_tickers_search_action(payload) {
  return {
    type: "LISTE_TICKER_SEARCH",
    payload,
  }
}

export function liste_tickers_search_action_done(request) {
  return {
    type: "LISTE_TICKER_SEARCH_DONE",
    payload: request,
  }
}

// Liste tickers search value
export function liste_tickers_search_value_action(payload) {
  return {
    type: "LISTE_TICKER_SEARCH_VALUE",
    payload,
  }
}

export function liste_tickers_search_value_action_done(request) {
  return {
    type: "LISTE_TICKER_SEARCH_VALUE_DONE",
    payload: request,
  }
}

// Info ticker
export function info_ticker_action(payload) {
  return {
    type: "INFO_TICKER",
    payload,
  }
}

export function info_ticker_action_done(request) {
  return {
    type: "INFO_TICKER_DONE",
    payload: request,
  }
}

// Year info ticker
export function year_info_ticker_action(payload) {
  return {
    type: "YEAR_INFO_TICKER",
    payload,
  }
}

export function year_info_ticker_action_done(request) {
  return {
    type: "YEAR_INFO_TICKER_DONE",
    payload: request,
  }
}

// Add ticker
export function add_ticker_action(payload) {
  return {
    type: "ADD_TICKER",
    payload,
  }
}

export function add_ticker_action_done(request) {
  return {
    type: "ADD_TICKER_DONE",
    payload: request,
  }
}

// Update ticker
export function update_ticker_action(payload) {
  return {
    type: "UPDATE_TICKER",
    payload,
  }
}

export function update_ticker_action_done(request) {
  return {
    type: "UPDATE_TICKER_DONE",
    payload: request,
  }
}

// Contact ticker
export function contact_ticker_action(payload) {
  return {
    type: "CONTACT_TICKER",
    payload,
  }
}

export function contact_ticker_action_done(request) {
  return {
    type: "CONTACT_TICKER_DONE",
    payload: request,
  }
}

// Add Contact ticker
export function add_contact_ticker_action(payload) {
  return {
    type: "ADD_CONTACT_TICKER",
    payload,
  }
}

export function add_contact_ticker_action_done(request) {
  return {
    type: "ADD_CONTACT_TICKER_DONE",
    payload: request,
  }
}

// Edit Contact ticker
export function edit_contact_ticker_action(payload) {
  return {
    type: "EDIT_CONTACT_TICKER",
    payload,
  }
}

export function edit_contact_ticker_action_done(request) {
  return {
    type: "EDIT_CONTACT_TICKER_DONE",
    payload: request,
  }
}

// AM ticker
export function am_ticker_action(payload) {
  return {
    type: "AM_TICKER",
    payload,
  }
}

export function am_ticker_action_done(request) {
  return {
    type: "AM_TICKER_DONE",
    payload: request,
  }
}

// Date Am ticker
export function date_am_ticker_action(payload) {
  return {
    type: "DATE_AM_TICKER",
    payload,
  }
}

export function date_am_ticker_action_done(request) {
  return {
    type: "DATE_AM_TICKER_DONE",
    payload: request,
  }
}

// Membre ticker
export function membre_ticker_action(payload) {
  return {
    type: "MEMBRE_TICKER",
    payload,
  }
}

export function membre_ticker_action_done(request) {
  return {
    type: "MEMBRE_TICKER_DONE",
    payload: request,
  }
}

// Add Membre ticker
export function add_membre_ticker_action(payload) {
  return {
    type: "ADD_MEMBRE_TICKER",
    payload,
  }
}

export function add_membre_ticker_action_done(request) {
  return {
    type: "ADD_MEMBRE_TICKER_DONE",
    payload: request,
  }
}

// Add am ticker
export function add_am_ticker_action(payload) {
  return {
    type: "ADD_AM_TICKER",
    payload,
  }
}

export function add_am_ticker_action_done(request) {
  return {
    type: "ADD_AM_TICKER_DONE",
    payload: request,
  }
}

// Edit Membre ticker
export function edit_membre_ticker_action(payload) {
  return {
    type: "EDIT_MEMBRE_TICKER",
    payload,
  }
}

export function edit_membre_ticker_action_done(request) {
  return {
    type: "EDIT_MEMBRE_TICKER_DONE",
    payload: request,
  }
}

// Edit Am ticker
export function edit_am_ticker_action(payload) {
  return {
    type: "EDIT_AM_TICKER",
    payload,
  }
}

export function edit_am_ticker_action_done(request) {
  return {
    type: "EDIT_AM_TICKER_DONE",
    payload: request,
  }
}

// Depense ticker
export function depense_ticker_action(payload) {
  return {
    type: "DEPENSE_TICKER",
    payload,
  }
}

export function depense_ticker_action_done(request) {
  return {
    type: "DEPENSE_TICKER_DONE",
    payload: request,
  }
}

// Allocation ticker
export function allocation_ticker_action(payload) {
  return {
    type: "ALLOCATION_TICKER",
    payload,
  }
}

export function allocation_ticker_action_done(request) {
  return {
    type: "ALLOCATION_TICKER_DONE",
    payload: request,
  }
}

// Liste tickers alerts
export function liste_tickers_alertes_action(payload) {
  return {
    type: "LISTE_TICKER_ALERTES",
    payload,
  }
}

export function liste_tickers_alertes_action_done(request) {
  return {
    type: "LISTE_TICKER_ALERTES_DONE",
    payload: request,
  }
}

// Liste statuts tickers
export function liste_statuts_tickers_action(payload) {
  return {
    type: "LISTE_STATUTS_TICKER",
    payload,
  }
}

export function liste_statuts_tickers_action_done(request) {
  return {
    type: "LISTE_STATUTS_TICKER_DONE",
    payload: request,
  }
}

// Liste categories
export function liste_categories_action(payload) {
  return {
    type: "LISTE_CATEGORIE",
    payload,
  }
}

export function liste_categories_action_done(request) {
  return {
    type: "LISTE_CATEGORIE_DONE",
    payload: request,
  }
}

// Liste type categories
export function liste_type_categories_action(payload) {
  return {
    type: "LISTE_TYPE_CATEGORIE",
    payload,
  }
}

export function liste_type_categories_action_done(request) {
  return {
    type: "LISTE_TYPE_CATEGORIE_DONE",
    payload: request,
  }
}

// Liste users
export function liste_users_action(payload) {
  return {
    type: "LISTE_USERS",
    payload,
  }
}

export function liste_users_action_done(request) {
  return {
    type: "LISTE_USERS_DONE",
    payload: request,
  }
}

// Liste am
export function liste_am_action(payload) {
  return {
    type: "LISTE_AM",
    payload,
  }
}

export function liste_am_action_done(request) {
  return {
    type: "LISTE_AM_DONE",
    payload: request,
  }
}

// Liste user team
export function liste_user_team_action(payload) {
  return {
    type: "LISTE_USER_TEAM",
    payload,
  }
}

export function liste_user_team_action_done(request) {
  return {
    type: "LISTE_USER_TEAM_DONE",
    payload: request,
  }
}

// Liste marque
export function liste_marque_action(payload) {
  return {
    type: "LISTE_MARQUE",
    payload,
  }
}

export function liste_marque_action_done(request) {
  return {
    type: "LISTE_MARQUE_DONE",
    payload: request,
  }
}

// Liste marque search
export function liste_marque_search_action(payload) {
  return {
    type: "LISTE_MARQUE_SEARCH",
    payload,
  }
}

export function liste_marque_search_action_done(request) {
  return {
    type: "LISTE_MARQUE_SEARCH_DONE",
    payload: request,
  }
}

// Liste client
export function liste_client_action(payload) {
  return {
    type: "LISTE_CLIENT",
    payload,
  }
}

export function liste_client_action_done(request) {
  return {
    type: "LISTE_CLIENT_DONE",
    payload: request,
  }
}

// Liste bu
export function liste_bu_action(payload) {
  return {
    type: "LISTE_BU",
    payload,
  }
}

export function liste_bu_action_done(request) {
  return {
    type: "LISTE_BU_DONE",
    payload: request,
  }
}

// Ticker Stat data controller
export function ticker_date_controller_action(payload) {
  return {
    type: "TICKER_DATE_CONTROLLER",
    payload,
  }
}

export function ticker_date_controller_action_done(request) {
  return {
    type: "TICKER_DATE_CONTROLLER_DONE",
    payload: request,
  }
}

// Ticker User Stat
export function ticker_user_stat_action(payload) {
  return {
    type: "TICKER_USER_STAT",
    payload,
  }
}

export function ticker_user_stat_action_done(request) {
  return {
    type: "TICKER_USER_STAT_DONE",
    payload: request,
  }
}

// Liste Lettre Ticker
export function liste_lettre_ticker_action(payload) {
  return {
    type: "LETTRE_TICKER",
    payload,
  }
}

export function liste_lettre_ticker_action_done(request) {
  return {
    type: "LETTRE_TICKER_DONE",
    payload: request,
  }
}

// Send message retard
export function send_message_retard_action(payload) {
  return {
    type: "SEND_MESSAGE_RETARD",
    payload,
  }
}

export function send_message_retard_action_done(request) {
  return {
    type: "SEND_MESSAGE_RETARD_DONE",
    payload: request,
  }
}
