import { call, takeEvery } from "redux-saga/effects"
import {
  create_demande_explication_action_done,
  create_reponse_explication_action_done,
  liste_explication_action_done,
  liste_explication_author_action_done,
  liste_rapport_user_ticker_action_done,
  update_demande_explication_action_done
} from "../actions/explication"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, patch, post } from "../services/request"

// liste_rapport_user_ticker
function* liste_rapport_user_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_TIMESHEETUSERTICKER}?time=${action.payload.type}&date=${action.payload.date}&ticker=${action.payload.ticker}&user=${action.payload.user}`,
    get,
    null,
    liste_rapport_user_ticker_action_done,
  )
}

export function* watch_liste_rapport_user_ticker_saga() {
  yield takeEvery("LISTE_RAPPORT_USER_TICKER", liste_rapport_user_ticker_saga)
}


// liste_explication
function* liste_explication_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LISTEXPLICATION}${action.payload.user ? "?user=" + action.payload.user : ""}`,
    get,
    null,
    liste_explication_action_done,
  )
}

export function* watch_liste_explication_saga() {
  yield takeEvery("LISTE_EXPLICATION", liste_explication_saga)
}


// liste_explication_author
function* liste_explication_author_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LISTEXPLICATION}${action.payload.author ? "?author=" + action.payload.author : ""}`,
    get,
    null,
    liste_explication_author_action_done,
  )
}

export function* watch_liste_explication_author_saga() {
  yield takeEvery("LISTE_EXPLICATION_AUTHOR", liste_explication_author_saga)
}


// create_demande_explication
function* create_demande_explication_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CREATEDEMANDEEXPLICATION}`,
    post,
    action.payload,
    create_demande_explication_action_done,
  )
}

export function* watch_create_demande_explication_saga() {
  yield takeEvery("CREATE_DEMANDE_EXPLICATION", create_demande_explication_saga)
}

// update_demande_explication
function* update_demande_explication_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATEDEMANDEEXPLICATION}${action.payload.id}/`,
    patch,
    action.payload.data,
    update_demande_explication_action_done,
  )
}

export function* watch_update_demande_explication_saga() {
  yield takeEvery("UPDATE_DEMANDE_EXPLICATION", update_demande_explication_saga)
}


// create_reponse_explication
function* create_reponse_explication_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CREATERESPONSEEXPLICATION}`,
    post,
    action.payload,
    create_reponse_explication_action_done,
  )
}

export function* watch_create_reponse_explication_saga() {
  yield takeEvery("CREATE_REPONSE_EXPLICATION", create_reponse_explication_saga)
}
