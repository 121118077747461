export const initialState = {
  liste_marques: [],
  detail_marque: {},
  marque_updated: {},
  marque_controller: {},
  marque_stat: {},
  marque_added: {},
  allocation_marque: {},
  depense_marque: {},
  facture_marque: {},
}

const marque = (state = {}, action) => {
  switch (action.type) {
  case "LISTE_MARQUES_DONE":
    return {
      ...state,
      liste_marques: action.payload,
    }
  case "ADD_MARQUE_DONE":
    return {
      ...state,
      marque_added: action.payload,
    }
  case "INFO_MARQUE_DONE":
    return {
      ...state,
      detail_marque: action.payload,
    }
  case "UPDATE_MARQUE_DONE":
    return {
      ...state,
      marque_updated: action.payload,
    }
  case "MARQUE_DATE_CONTROLLER_DONE":
    return {
      ...state,
      marque_controller: action.payload,
    }
  case "MARQUE_USER_STAT_DONE":
    return {
      ...state,
      marque_stat: action.payload,
    }
  case "ALLOCATION_MARQUE_DONE":
    return {
      ...state,
      allocation_marque: action.payload,
    }
  case "DEPENSE_MARQUE_DONE":
    return {
      ...state,
      depense_marque: action.payload,
    }
  case "FACTURE_MARQUE_DONE":
    return {
      ...state,
      facture_marque: action.payload,
    }
  default:
    return state
  }
}

export default marque
