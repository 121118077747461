import React, { Component } from "react"


export default class Formbutton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      classBtn: this.props.classname,
      textBtn: this.props.textBtn || "Connexion"
    }
  }

  showLoading() {
    this.setState({ classBtn: "btn btn-primary disabled", disabled: "disabled" })
  }

  componentDidUpdate(prevProps) {
    if (this.props.classname !== this.state.classBtn)
      this.setState({ classBtn: this.props.classname })
  }

  render() {
    return (
      <button
        style={{ backgroundColor: this.props.colorBtn, borderColor: this.props.colorBtn, padding: "5px 25px" }}
        type="submit"
        onClick={this.props.onClick}
        className={this.props.disabled ? this.state.classBtn + " disabled" : this.state.classBtn}
        disabled={this.props.disabled || this.props.disabledBloc || this.props.disabledBloc2}
      >
        <span>{this.state.textBtn}</span>
      </button>)
  }
}
