// Liste marques
export function liste_marques_action(payload) {
  return {
    type: "LISTE_MARQUES",
    payload,
  }
}

export function liste_marques_action_done(request) {
  return {
    type: "LISTE_MARQUES_DONE",
    payload: request,
  }
}

// Add marque
export function add_marque_action(payload) {
  return {
    type: "ADD_MARQUE",
    payload,
  }
}

export function add_marque_action_done(request) {
  return {
    type: "ADD_MARQUE_DONE",
    payload: request,
  }
}

// Detail_marque
export function info_marque_action(payload) {
  return {
    type: "INFO_MARQUE",
    payload,
  }
}

export function info_marque_action_done(request) {
  return {
    type: "INFO_MARQUE_DONE",
    payload: request,
  }
}

// Update_marque
export function update_marque_action(payload) {
  return {
    type: "UPDATE_MARQUE",
    payload,
  }
}

export function update_marque_action_done(request) {
  return {
    type: "UPDATE_MARQUE_DONE",
    payload: request,
  }
}


// Marque Stat data controller
export function marque_date_controller_action(payload) {
  return {
    type: "MARQUE_DATE_CONTROLLER",
    payload,
  }
}

export function marque_date_controller_action_done(request) {
  return {
    type: "MARQUE_DATE_CONTROLLER_DONE",
    payload: request,
  }
}

// Marque User Stat
export function marque_user_stat_action(payload) {
  return {
    type: "MARQUE_USER_STAT",
    payload,
  }
}

export function marque_user_stat_action_done(request) {
  return {
    type: "MARQUE_USER_STAT_DONE",
    payload: request,
  }
}

// Allocation marque
export function allocation_marque_action(payload) {
  return {
    type: "ALLOCATION_MARQUE",
    payload,
  }
}

export function allocation_marque_action_done(request) {
  return {
    type: "ALLOCATION_MARQUE_DONE",
    payload: request,
  }
}

// Depense marque
export function depense_marque_action(payload) {
  return {
    type: "DEPENSE_MARQUE",
    payload,
  }
}

export function depense_marque_action_done(request) {
  return {
    type: "DEPENSE_MARQUE_DONE",
    payload: request,
  }
}

// Facture marque
export function facture_marque_action(payload) {
  return {
    type: "FACTURE_MARQUE",
    payload,
  }
}

export function facture_marque_action_done(request) {
  return {
    type: "FACTURE_MARQUE_DONE",
    payload: request,
  }
}
