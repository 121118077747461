import { call, takeEvery } from "redux-saga/effects"
import {
  categorie_remboursement_action_done,
  create_order_action_done,
  create_refund_action_done,
  delete_order_action_done,
  delete_refund_action_done,
  get_moyen_paiement_action_done,
  get_moyen_remboursement_action_done,
  get_ordre_mission_action_done,
  get_remboursement_action_done,
  get_remboursement_rejete_action_done,
  get_remboursement_rembourse_action_done,
  get_remboursement_valide_action_done,
  statut_ordre_action_done,
  statut_remboursement_action_done,
  update_order_action_done,
  update_refund_action_done
} from "../actions/noteFrais"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, patch, post } from "../services/request"


// get_ordre_mission
function* get_ordre_mission_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LIST_ORDRE}?page=${action.payload.page}${action.payload.user ? "&user=" + action.payload.user : ""}${action.payload.search ? "&search=" + action.payload.search : ""}`,
    get,
    null,
    get_ordre_mission_action_done,
  )
}

export function* watch_get_ordre_mission_saga() {
  yield takeEvery("LIST_ORDRES_MISSIONS", get_ordre_mission_saga)
}

// get_remboursement
function* get_remboursement_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LIST_ORDRE}?page=${action.payload.page}${action.payload.am ? "&am=" + action.payload.am : ""}${action.payload.search ? "&search=" + action.payload.search : ""}&statut=${action.payload.statut}`,
    get,
    null,
    get_remboursement_action_done,
  )
}

export function* watch_get_remboursement_saga() {
  yield takeEvery("LIST_REMBOURSEMENT", get_remboursement_saga)
}

// get_remboursement
function* get_remboursement_valide_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LIST_ORDRE}?page=${action.payload.page}${action.payload.am ? "&am=" + action.payload.am : ""}${action.payload.search ? "&search=" + action.payload.search : ""}&statut=${action.payload.statut}`,
    get,
    null,
    get_remboursement_valide_action_done,
  )
}

export function* watch_get_remboursement_valide_saga() {
  yield takeEvery("LIST_REMBOURSEMENT_VALIDE", get_remboursement_valide_saga)
}

// get_remboursement_rembourse
function* get_remboursement_rembourse_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LIST_ORDRE}?page=${action.payload.page}${action.payload.am ? "&am=" + action.payload.am : ""}${action.payload.search ? "&search=" + action.payload.search : ""}&statut=${action.payload.statut}`,
    get,
    null,
    get_remboursement_rembourse_action_done,
  )
}

export function* watch_get_remboursement_rembourse_saga() {
  yield takeEvery("LIST_REMBOURSEMENT_REMBOURSE", get_remboursement_rembourse_saga)
}

// get_remboursement_rejete
function* get_remboursement_rejete_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LIST_ORDRE}?page=${action.payload.page}${action.payload.am ? "&am=" + action.payload.am : ""}${action.payload.search ? "&search=" + action.payload.search : ""}&statut=${action.payload.statut}`,
    get,
    null,
    get_remboursement_rejete_action_done,
  )
}

export function* watch_get_remboursement_rejete_saga() {
  yield takeEvery("LIST_REMBOURSEMENT_REJETE", get_remboursement_rejete_saga)
}

// get_moyen_paiement
function* get_moyen_paiement_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_MOYEN_PAIEMNT}`,
    get,
    null,
    get_moyen_paiement_action_done,
  )
}

export function* watch_get_moyen_paiement_saga() {
  yield takeEvery("MOYEN_PAIEMNT", get_moyen_paiement_saga)
}

// get_moyen_remboursement
function* get_moyen_remboursement_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_MOYEN_REMBOURSEMENT}`,
    get,
    null,
    get_moyen_remboursement_action_done,
  )
}

export function* watch_get_moyen_remboursement_saga() {
  yield takeEvery("MOYEN_REMBOURSEMENT", get_moyen_remboursement_saga)
}

// categorie_remboursement
function* categorie_remboursement_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CATEGORIEREMBOURSEMENT}`,
    get,
    null,
    categorie_remboursement_action_done,
  )
}

export function* watch_categorie_remboursement_saga() {
  yield takeEvery("CATEGORIE_REMBOURSEMENT", categorie_remboursement_saga)
}

// statut_ordre
function* statut_ordre_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_STATUTORDRE}`,
    get,
    null,
    statut_ordre_action_done,
  )
}

export function* watch_statut_ordre_saga() {
  yield takeEvery("STATUT_ORDRE", statut_ordre_saga)
}

// statut_remboursement
function* statut_remboursement_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_STATUTREMBOURSEMENT}${action.payload.statut === 1 ? "?statut_am=True" : "?statut_comptable=True"}`,
    get,
    null,
    statut_remboursement_action_done,
  )
}

export function* watch_statut_remboursement_saga() {
  yield takeEvery("STATUT_REMBOURSEMENT", statut_remboursement_saga)
}

// create_order
function* create_order_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_STATUTORDRE}`,
    post,
    action.payload,
    create_order_action_done,
  )
}

export function* watch_create_order_saga() {
  yield takeEvery("CREATE_ORDER", create_order_saga)
}

// update_order
function* update_order_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATE_ORDRE}${action.payload.id}`,
    patch,
    action.payload.data,
    update_order_action_done,
  )
}

export function* watch_update_order_saga() {
  yield takeEvery("UPDATE_ORDER", update_order_saga)
}

// delete_order
function* delete_order_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATE_ORDRE}${action.payload.id}`,
    patch,
    action.payload.data,
    delete_order_action_done,
  )
}

export function* watch_delete_order_saga() {
  yield takeEvery("DELETE_ORDER", delete_order_saga)
}

// create_refund
function* create_refund_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CREATE_REFUND}`,
    post,
    action.payload,
    create_refund_action_done,
  )
}

export function* watch_create_refund_saga() {
  yield takeEvery("CREATE_REFUND", create_refund_saga)
}

// update_refund
function* update_refund_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATE_REFUND}${action.payload.id}`,
    patch,
    action.payload.data,
    update_refund_action_done,
  )
}

export function* watch_update_refund_saga() {
  yield takeEvery("UPDATE_REFUND", update_refund_saga)
}

// delete_refund
function* delete_refund_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATE_REFUND}${action.payload.id}`,
    patch,
    action.payload.data,
    delete_refund_action_done,
  )
}

export function* watch_delete_refund_saga() {
  yield takeEvery("DELETE_REFUND", delete_refund_saga)
}
