export const initialState = {
  liste_tickers: [],
  mes_tickers_15j_stat: [],
  mes_tickers_15j: [],
  mes_tickers_1mois: [],
  mes_tickers_sans_activite: [],
  mes_tickers_passif: [],
  mes_tickers_marques: [],
  liste_tickers_super_user: [],
  liste_tickers_search: [],
  liste_tickers_search_value: [],
  liste_tickers_alertes: [],
  Liste_statut_tickers: [],
  detail_ticker: {},
  year_info_ticker: {},
  update_ticker: {},
  add_ticker: {},
  am_ticker: {},
  date_am_ticker: {},
  contact_ticker: {},
  add_contact_ticker: {},
  edit_contact_ticker: {},
  membre_ticker: {},
  add_membre_ticker: {},
  add_am_ticker: {},
  edit_membre_ticker: {},
  edit_am_ticker: {},
  ticker_controller: {},
  ticker_stat: {},
  allocation_ticker: {},
  depense_ticker: {},

  liste_categories: [],
  liste_type_categories: [],
  liste_users: [],
  liste_am: [],
  liste_user_team: [],
  liste_marque: [],
  liste_marque_search: [],
  liste_client: [],
  liste_bu: [],
  liste_lettre: [],
  message_send: [],
}

const ticker = (state = {}, action) => {
  switch (action.type) {
  case "LISTE_TICKER_DONE":
    return {
      ...state,
      liste_tickers: action.payload,
    }
  case "MES_TICKER_15J_DONE":
    return {
      ...state,
      mes_tickers_15j: action.payload,
    }
  case "MES_TICKER_15J_STAT_DONE":
    return {
      ...state,
      mes_tickers_15j_stat: action.payload,
    }
  case "MES_TICKER_1MOIS_DONE":
    return {
      ...state,
      mes_tickers_1mois: action.payload,
    }
  case "MES_TICKER_SANS_ACTIVITE_DONE":
    return {
      ...state,
      mes_tickers_sans_activite: action.payload,
    }
  case "MES_TICKER_PASSIF_DONE":
    return {
      ...state,
      mes_tickers_passif: action.payload,
    }
  case "MES_TICKER_MARQUE_DONE":
    return {
      ...state,
      mes_tickers_marques: action.payload,
    }
  case "LISTE_TICKER_SUPER_USER_DONE":
    return {
      ...state,
      liste_tickers_super_user: action.payload,
    }
  case "LISTE_TICKER_SEARCH_DONE":
    return {
      ...state,
      liste_tickers_search: action.payload,
    }
  case "LISTE_TICKER_SEARCH_VALUE_DONE":
    return {
      ...state,
      liste_tickers_search_value: action.payload,
    }
  case "INFO_TICKER_DONE":
    return {
      ...state,
      detail_ticker: action.payload,
    }
  case "YEAR_INFO_TICKER_DONE":
    return {
      ...state,
      year_info_ticker: action.payload,
    }
  case "ADD_TICKER_DONE":
    return {
      ...state,
      add_ticker: action.payload,
    }
  case "UPDATE_TICKER_DONE":
    return {
      ...state,
      update_ticker: action.payload,
    }
  case "CONTACT_TICKER_DONE":
    return {
      ...state,
      contact_ticker: action.payload,
    }
  case "ADD_CONTACT_TICKER_DONE":
    return {
      ...state,
      add_contact_ticker: action.payload,
    }
  case "EDIT_CONTACT_TICKER_DONE":
    return {
      ...state,
      edit_contact_ticker: action.payload,
    }
  case "AM_TICKER_DONE":
    return {
      ...state,
      am_ticker: action.payload,
    }
  case "DATE_AM_TICKER_DONE":
    return {
      ...state,
      date_am_ticker: action.payload,
    }
  case "MEMBRE_TICKER_DONE":
    return {
      ...state,
      membre_ticker: action.payload,
    }
  case "ADD_MEMBRE_TICKER_DONE":
    return {
      ...state,
      add_membre_ticker: action.payload,
    }
  case "ADD_AM_TICKER_DONE":
    return {
      ...state,
      add_am_ticker: action.payload,
    }
  case "EDIT_MEMBRE_TICKER_DONE":
    return {
      ...state,
      edit_membre_ticker: action.payload,
    }
  case "EDIT_AM_TICKER_DONE":
    return {
      ...state,
      edit_am_ticker: action.payload,
    }
  case "DEPENSE_TICKER_DONE":
    return {
      ...state,
      depense_ticker: action.payload,
    }
  case "ALLOCATION_TICKER_DONE":
    return {
      ...state,
      allocation_ticker: action.payload,
    }
  case "LISTE_TICKER_ALERTES_DONE":
    return {
      ...state,
      liste_tickers_alertes: action.payload,
    }
  case "LISTE_STATUTS_TICKER_DONE":
    return {
      ...state,
      Liste_statut_tickers: action.payload,
    }
  case "LISTE_CATEGORIE_DONE":
    return {
      ...state,
      liste_categories: action.payload,
    }
  case "LISTE_TYPE_CATEGORIE_DONE":
    return {
      ...state,
      liste_type_categories: action.payload,
    }
  case "LISTE_USERS_DONE":
    return {
      ...state,
      liste_users: action.payload,
    }
  case "LISTE_AM_DONE":
    return {
      ...state,
      liste_am: action.payload,
    }
  case "LISTE_USER_TEAM_DONE":
    return {
      ...state,
      liste_user_team: action.payload,
    }
  case "LISTE_MARQUE_DONE":
    return {
      ...state,
      liste_marque: action.payload,
    }
  case "LISTE_MARQUE_SEARCH_DONE":
    return {
      ...state,
      liste_marque_search: action.payload,
    }
  case "LISTE_CLIENT_DONE":
    return {
      ...state,
      liste_client: action.payload,
    }
  case "LISTE_BU_DONE":
    return {
      ...state,
      liste_bu: action.payload,
    }
  case "TICKER_DATE_CONTROLLER_DONE":
    return {
      ...state,
      ticker_controller: action.payload,
    }
  case "TICKER_USER_STAT_DONE":
    return {
      ...state,
      ticker_stat: action.payload,
    }
  case "LETTRE_TICKER_DONE":
    return {
      ...state,
      liste_lettre: action.payload,
    }
  case "SEND_MESSAGE_RETARD_DONE":
    return {
      ...state,
      message_send: action.payload,
    }
  default:
    return state
  }
}

export default ticker
