export const initialState = {
  List_Type: [],
  List_attestation: [],
  List_attestation_cours: [],
  List_attestation_signe: [],
  List_all_attestation: [],
  List_all_attestation_cours: [],
  List_all_attestation_signe: [],
  attestation_added: "",
  attestation_valide: "",
}
const attestation = (state = initialState, action) => {
  switch (action.type) {
  case "DEMANDE_ATTESTATION_DONE": {
    return {
      ...state,
      attestation_added: action.payload
    }
  }
  case "LIST_TYPE_DONE": {
    return {
      ...state,
      List_Type: action.payload
    }
  }
  case "VALIDE_DEMANDE_DONE": {
    return {
      ...state,
      attestation_valide: action.payload
    }
  }
  case "LIST_ATTESTATION_DONE": {
    return {
      ...state,
      List_attestation: action.payload
    }
  }
  case "LIST_ATTESTATION_EN_COURS_SIGNATURE_DONE": {
    return {
      ...state,
      List_attestation_cours: action.payload
    }
  }
  case "LIST_ATTESTATION_SIGNE_DONE": {
    return {
      ...state,
      List_attestation_signe: action.payload
    }
  }
  case "LIST_ALL_ATTESTATION_DONE": {
    return {
      ...state,
      List_all_attestation: action.payload
    }
  }
  case "LIST_ALL_ATTESTATION_EN_COURS_SIGNATURE_DONE": {
    return {
      ...state,
      List_all_attestation_cours: action.payload
    }
  }
  case "LIST_ALL_ATTESTATION_SIGNE_DONE": {
    return {
      ...state,
      List_all_attestation_signe: action.payload
    }
  }
  default:
    return state
  }
}

export default attestation
