// Liste factures
export function liste_factures_action(payload) {
  return {
    type: "LISTE_FACTURE",
    payload,
  }
}

export function liste_factures_action_done(request) {
  return {
    type: "LISTE_FACTURE_DONE",
    payload: request,
  }
}

// Add facture
export function add_facture_action(payload) {
  return {
    type: "ADD_FACTURE",
    payload,
  }
}

export function add_facture_action_done(request) {
  return {
    type: "ADD_FACTURE_DONE",
    payload: request,
  }
}

// update facture
export function update_facture_action(payload) {
  return {
    type: "UPDATE_FACTURE",
    payload,
  }
}

export function update_facture_action_done(request) {
  return {
    type: "UPDATE_FACTURE_DONE",
    payload: request,
  }
}

// Liste facturation
export function facturation_action(payload) {
  return {
    type: "FACTURATION",
    payload,
  }
}

export function facturation_action_done(request) {
  return {
    type: "FACTURATION_DONE",
    payload: request,
  }
}

// Liste Offre allocation
export function liste_allocation_offre_action(payload) {
  return {
    type: "ALLOCATION_OFFRE",
    payload,
  }
}

export function liste_allocation_offre_action_done(request) {
  return {
    type: "ALLOCATION_OFFRE_DONE",
    payload: request,
  }
}

// Liste des entite
export function entite_action(payload) {
  return {
    type: "ENTITE",
    payload,
  }
}

export function entite_action_done(request) {
  return {
    type: "ENTITE_DONE",
    payload: request,
  }
}

// Statut facture
export function statut_facture_action(payload) {
  return {
    type: "STATUT_FACTURE",
    payload,
  }
}

export function statut_facture_action_done(request) {
  return {
    type: "STATUT_FACTURE_DONE",
    payload: request,
  }
}

// revenue liste
export function revenue_liste_action(payload) {
  return {
    type: "REVENUE_LIST",
    payload,
  }
}

export function revenue_liste_action_done(request) {
  return {
    type: "REVENUE_LIST_DONE",
    payload: request,
  }
}

// liste_allocation_offre_facture
export function liste_allocation_offre_facture_action(payload) {
  return {
    type: "ALLOCATION_OFFRE_FACTURE",
    payload,
  }
}

export function liste_allocation_offre_facture_action_done(request) {
  return {
    type: "ALLOCATION_OFFRE_FACTURE_DONE",
    payload: request,
  }
}

// facture_ticker
export function facture_ticker_action(payload) {
  return {
    type: "FACTURE_TICKER",
    payload,
  }
}

export function facture_ticker_action_done(request) {
  return {
    type: "FACTURE_TICKER_DONE",
    payload: request,
  }
}

// statistique_facture
export function statistique_facture_action(payload) {
  return {
    type: "STATISTIQUE_FACTURE",
    payload,
  }
}

export function statistique_facture_action_done(request) {
  return {
    type: "STATISTIQUE_FACTURE_DONE",
    payload: request,
  }
}
