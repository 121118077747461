export const initialState = {
  List_conge: [],
  List_all: [],
  List_all_valide: [],
  List_all_rejete: [],
  List_conge_valide: [],
  List_conge_rejete: [],
  List_conge_annulee: [],
  List_conge_equipe: [],
  List_conge_equipe_valide: [],
  List_conge_equipe_rejete: [],
  List_gain: [],
  List_solde_conge: [],
  List_team: [],
  conge_added: "",
  conge_edited: "",
  conge_annuler: "",
  conge_upload: "",
  statut_changed: "",
  statut_document: "",
  pdfTelecharger: ""
}
const conge = (state = initialState, action) => {
  switch (action.type) {
  case "ADD_CONGE_DONE": {
    return {
      ...state,
      conge_added: action.payload
    }
  }
  case "LISTE_CONGE_DONE": {
    return {
      ...state,
      List_conge: action.payload,
    }
  }
  case "LISTE_CONGE_VALIDE_DONE": {
    return {
      ...state,
      List_conge_valide: action.payload,
    }
  }
  case "LISTE_CONGE_REFUSE_DONE": {
    return {
      ...state,
      List_conge_rejete: action.payload,
    }
  }
  case "LISTE_CONGE_ANNULE_DONE": {
    return {
      ...state,
      List_conge_annulee: action.payload,
    }
  }
  case "LISTE_SUB_CONGE_DONE": {
    return {
      ...state,
      List_conge_equipe: action.payload,
    }
  }
  case "LISTE_SUB_CONGE_VALIDE_DONE": {
    return {
      ...state,
      List_conge_equipe_valide: action.payload,
    }
  }
  case "LISTE_SUB_CONGE_REFUSE_DONE": {
    return {
      ...state,
      List_conge_equipe_rejete: action.payload,
    }
  }
  case "LISTE_ALL_CONGE_DONE": {
    return {
      ...state,
      List_all: action.payload,
    }
  }
  case "LISTE_ALL_CONGE_VALIDE_DONE": {
    return {
      ...state,
      List_all_valide: action.payload,
    }
  }
  case "LISTE_ALL_CONGE_REFUSE_DONE": {
    return {
      ...state,
      List_all_rejete: action.payload,
    }
  }
  case "EDIT_CONGE_DONE": {
    return {
      ...state,
      conge_edited: "modifié",
    }
  }
  case "ANNULER_CONGE_DONE": {
    return {
      ...state,
      conge_annuler: "Annulée",
    }
  }
  case "UPLOAD_CONGE_DONE": {
    return {
      ...state,
      conge_upload: "uploadé",
    }
  }
  case "VALIDATE_CONGE_DONE": {
    return {
      ...state,
      statut_changed: action.payload.data.validation_manager_2 === "validee" || action.payload.data.validation_manager_1 === "validee" ? "validée" : "rejetée",
      statut_document: action.payload,
    }
  }
  case "TELECHERGER_CONGE_DONE": {
    return {
      ...state,
      pdfTelecharger: action.payload
    }
  }
  case "SOLDE_GAIN_DONE": {
    return {
      ...state,
      List_gain: action.payload,
    }
  }
  case "TEAM_CONGE_DONE": {
    return {
      ...state,
      List_team: action.payload,
    }
  }
  case "USED_CONGE_DONE": {
    return {
      ...state,
      List_solde_conge: action.payload,
    }
  }
  case "RESET_STATUT_CHANGED": {
    return {
      ...state,
      statut_changed: "",
    }
  }
  default:
    return state
  }
}

export default conge
