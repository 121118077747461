import axios from "axios"
import Cookies from "universal-cookie"

const cookies = new Cookies()
let token = cookies.get("access_token")
let headers = { Authorization: "Bearer " + token }

export const get = (url) => {
  let response
  if (token) {
    response = axios.get(`${url}`, {
      headers: headers
    })
  } else {
    response = axios.get(`${url}`)
  }
  return response
}

export const getWithData = (url, data) => {
  let response
  if (token) {
    response = axios.get(`${url}`, {
      data,
      headers: headers
    })
  } else {
    response = axios.get(`${url}`, {
      data,
      headers: headers
    })
  }
  return response
}

export const post = (url, data) => {
  let response
  if (token) {
    response = axios.post(`${url}`, data, {
      headers: headers
    })
  } else {
    response = axios.post(`${url}`, data)
  }
  return response
}

export const patch = (url, data) => {
  let response
  if (token) {
    response = axios.patch(`${url}`, data, {
      headers: headers
    })
  } else {
    response = axios.patch(`${url}`, data)
  }
  return response
}

export const deleteR = (url) => {
  let response
  if (token) {
    response = axios.delete(`${url}`, {
      headers: headers
    })
  } else {
    response = axios.delete(`${url}`)
  }
  return response
}
