import { call, takeEvery } from "redux-saga/effects"
import {
  add_maladie_action_done,
  edit_maladie_action_done,
  get_all_maladie_action_done,
  get_all_maladie_uploade_action_done,
  get_all_maladie_valide_action_done,
  get_maladie_action_done,
  get_maladie_uploade_action_done,
  get_maladie_valide_action_done
} from "../actions/maladie"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, patch, post } from "../services/request"


// add_maladie
function* add_maladie_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_MALADIE}`,
    post,
    action.payload,
    add_maladie_action_done
  )
}

export function* watch_add_maladie_saga() {
  yield takeEvery("ADD_MALADIE", add_maladie_saga)
}


// edit_maladie
function* edit_maladie_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_MALADIE}${action.payload.id}/`,
    patch,
    action.payload.data,
    edit_maladie_action_done
  )
}

export function* watch_edit_maladie_saga() {
  yield takeEvery("EDIT_MALADIE", edit_maladie_saga)
}


// list_maladie
function* list_maladie_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_MALADIE}?page=${action.payload}&statut=en_attente_document`,
    get,
    null,
    get_maladie_action_done
  )
}

export function* watch_list_maladie_saga() {
  yield takeEvery("LIST_MALADIE", list_maladie_saga)
}


// list_maladie_uploade
function* list_maladie_uploade_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_MALADIE}?page=${action.payload}&statut=document_uploade`,
    get,
    null,
    get_maladie_uploade_action_done
  )
}

export function* watch_list_maladie_uploade_saga() {
  yield takeEvery("LIST_MALADIE_UPLOADE", list_maladie_uploade_saga)
}


// list_maladie_valide
function* list_maladie_valide_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_MALADIE}?page=${action.payload}&statut=document_valide`,
    get,
    null,
    get_maladie_valide_action_done
  )
}

export function* watch_list_maladie_valide_saga() {
  yield takeEvery("LIST_MALADIE_VALIDE", list_maladie_valide_saga)
}


// list_maladie
function* list_all_maladie_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_MALADIE}?page=${action.payload}&statut=en_attente_document&all=true`,
    get,
    null,
    get_all_maladie_action_done
  )
}

export function* watch_list_all_maladie_saga() {
  yield takeEvery("LIST_ALL_MALADIE", list_all_maladie_saga)
}


// list_maladie
function* list_all_maladie_uploade_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_MALADIE}?page=${action.payload}&statut=document_uploade&all=true`,
    get,
    null,
    get_all_maladie_uploade_action_done
  )
}

export function* watch_list_all_maladie_uploade_saga() {
  yield takeEvery("LIST_ALL_MALADIE_UPLOADE", list_all_maladie_uploade_saga)
}


// list_maladie
function* list_all_maladie_valide_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_MALADIE}?page=${action.payload}&statut=document_valide&all=true`,
    get,
    null,
    get_all_maladie_valide_action_done
  )
}

export function* watch_list_all_maladie_valide_saga() {
  yield takeEvery("LIST_ALL_MALADIE_VALIDE", list_all_maladie_valide_saga)
}

