import { call, takeEvery } from "redux-saga/effects"
import {
  add_attestation_action_done,
  get_all_attestation_action_done,
  get_all_attestation_cours_action_done,
  get_all_attestation_signe_action_done,
  get_attestation_action_done,
  get_attestation_cours_action_done,
  get_attestation_signe_action_done,
  get_types_action_done,
  validate_attestation_action_done
} from "../actions/attestation"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, patch, post } from "../services/request"


// add_attestation
function* add_attestation_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ATTESTATION}`,
    post,
    action.payload,
    add_attestation_action_done
  )
}

export function* watch_add_attestation_saga() {
  yield takeEvery("DEMANDE_ATTESTATION", add_attestation_saga)
}


// validate_attestation
function* validate_attestation_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ATTESTATION}${action.payload.id}/`,
    patch,
    action.payload.data,
    validate_attestation_action_done
  )
}

export function* watch_validate_attestation_saga() {
  yield takeEvery("VALIDE_DEMANDE", validate_attestation_saga)
}


// get_types
function* get_types_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_TYPE_DEMANDE}`,
    get,
    null,
    get_types_action_done
  )
}

export function* watch_get_types_saga() {
  yield takeEvery("LIST_TYPE", get_types_saga)
}


// get_attestation
function* get_attestation_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ATTESTATION}?page=${action.payload}&statut_attestation=en attente`,
    get,
    null,
    get_attestation_action_done
  )
}

export function* watch_get_attestation_saga() {
  yield takeEvery("LIST_ATTESTATION", get_attestation_saga)
}


// get_attestation_cours
function* get_attestation_cours_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ATTESTATION}?page=${action.payload}&statut_attestation=en_cours_signature`,
    get,
    null,
    get_attestation_cours_action_done
  )
}

export function* watch_get_attestation_cours_saga() {
  yield takeEvery("LIST_ATTESTATION_EN_COURS_SIGNATURE", get_attestation_cours_saga)
}


// get_attestation_cours
function* get_attestation_signe_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ATTESTATION}?page=${action.payload}&statut_attestation=signee`,
    get,
    null,
    get_attestation_signe_action_done
  )
}

export function* watch_get_attestation_signe_saga() {
  yield takeEvery("LIST_ATTESTATION_SIGNE", get_attestation_signe_saga)
}


// get_all_attestation
function* get_all_attestation_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ATTESTATION}?page=${action.payload}&statut_attestation=en attente&all=true`,
    get,
    null,
    get_all_attestation_action_done
  )
}

export function* watch_get_all_attestation_saga() {
  yield takeEvery("LIST_ALL_ATTESTATION", get_all_attestation_saga)
}


// get_attestation_cours
function* get_all_attestation_cours_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ATTESTATION}?page=${action.payload}&statut_attestation=en_cours_signature&all=true`,
    get,
    null,
    get_all_attestation_cours_action_done
  )
}

export function* watch_get_all_attestation_cours_saga() {
  yield takeEvery("LIST_ALL_ATTESTATION_EN_COURS_SIGNATURE", get_all_attestation_cours_saga)
}


// get_attestation_cours
function* get_all_attestation_signe_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_ATTESTATION}?page=${action.payload}&statut_attestation=signee&all=true`,
    get,
    null,
    get_all_attestation_signe_action_done
  )
}

export function* watch_get_all_attestation_signe_saga() {
  yield takeEvery("LIST_ALL_ATTESTATION_SIGNE", get_all_attestation_signe_saga)
}
