// Liste clients
export function liste_clients_action(payload) {
  return {
    type: "LISTE_CLIENTS",
    payload,
  }
}

export function liste_clients_action_done(request) {
  return {
    type: "LISTE_CLIENTS_DONE",
    payload: request,
  }
}

// Add client
export function add_client_action(payload) {
  return {
    type: "ADD_CLIENT",
    payload,
  }
}

export function add_client_action_done(request) {
  return {
    type: "ADD_CLIENT_DONE",
    payload: request,
  }
}

// Liste contacts
export function liste_contacts_action(payload) {
  return {
    type: "LISTE_CONTACTS",
    payload,
  }
}

export function liste_contacts_action_done(request) {
  return {
    type: "LISTE_CONTACTS_DONE",
    payload: request,
  }
}

// Rapport Client
export function rapport_client_action(payload) {
  return {
    type: "RAPPORT_CLIENT",
    payload,
  }
}

export function rapport_client_action_done(request) {
  return {
    type: "RAPPORT_CLIENT_DONE",
    payload: request,
  }
}

// Detail_client
export function info_client_action(payload) {
  return {
    type: "INFO_CLIENT",
    payload,
  }
}

export function info_client_action_done(request) {
  return {
    type: "INFO_CLIENT_DONE",
    payload: request,
  }
}

// Update_client
export function update_client_action(payload) {
  return {
    type: "UPDATE_CLIENT",
    payload,
  }
}

export function update_client_action_done(request) {
  return {
    type: "UPDATE_CLIENT_DONE",
    payload: request,
  }
}


// client Stat data controller
export function client_date_controller_action(payload) {
  return {
    type: "CLIENT_DATE_CONTROLLER",
    payload,
  }
}

export function client_date_controller_action_done(request) {
  return {
    type: "CLIENT_DATE_CONTROLLER_DONE",
    payload: request,
  }
}

// client User Stat
export function client_user_stat_action(payload) {
  return {
    type: "CLIENT_USER_STAT",
    payload,
  }
}

export function client_user_stat_action_done(request) {
  return {
    type: "CLIENT_USER_STAT_DONE",
    payload: request,
  }
}

// Allocation client
export function allocation_client_action(payload) {
  return {
    type: "ALLOCATION_CLIENT",
    payload,
  }
}

export function allocation_client_action_done(request) {
  return {
    type: "ALLOCATION_CLIENT_DONE",
    payload: request,
  }
}

// Depense client
export function depense_client_action(payload) {
  return {
    type: "DEPENSE_CLIENT",
    payload,
  }
}

export function depense_client_action_done(request) {
  return {
    type: "DEPENSE_CLIENT_DONE",
    payload: request,
  }
}

// Facture client
export function facture_client_action(payload) {
  return {
    type: "FACTURE_CLIENT",
    payload,
  }
}

export function facture_client_action_done(request) {
  return {
    type: "FACTURE_CLIENT_DONE",
    payload: request,
  }
}

// Ticker client
export function ticker_client_action(payload) {
  return {
    type: "TICKER_CLIENT",
    payload,
  }
}

export function ticker_client_action_done(request) {
  return {
    type: "TICKER_CLIENT_DONE",
    payload: request,
  }
}
