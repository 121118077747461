import { call, takeEvery } from "redux-saga/effects"
import urls from "../services/api"
import {
  edit_user_action_done,
  liste_permission_action_done,
  liste_team_action_done,
  liste_team_retard_action_done,
  liste_user_times_action_done,
  liste_user_times_download_action_done,
  liste_users_trombino_action_done,
  login_action_done,
  user_date_controller_action_done,
  user_info_action_done,
  user_stat_action_done
} from "../actions/user"
import { callApi } from "../services/Callapi"
import { get, post } from "../services/request"

// login
function* login_saga(action) {
  yield call(
    callApi,
    urls.apiUrl + urls.URL_LOGIN,
    post,
    action.payload,
    login_action_done,
  )
}

export function* watch_login_saga() {
  yield takeEvery("LOGIN", login_saga)
}


// liste_permissions
function* liste_permission_saga(action) {
  yield call(
    callApi,
    urls.apiUrl + urls.URL_GETPERMISSIONS,
    get,
    action.payload,
    liste_permission_action_done,
  )
}

export function* watch_liste_permission_saga() {
  yield takeEvery("LISTE_PERMISSION", liste_permission_saga)
}


// user_info
function* user_info_saga(action) {
  yield call(
    callApi,
    urls.apiUrl + urls.URL_GETUSER,
    get,
    action.payload,
    user_info_action_done,
  )
}

export function* watch_user_info_saga() {
  yield takeEvery("USER_INFO", user_info_saga)
}


// edit_user
function* edit_user_saga(action) {
  yield call(
    callApi,
    urls.apiUrl + urls.URL_GETUSER + action.payload.id + "/",
    post,
    action.payload.data,
    edit_user_action_done,
  )
}

export function* watch_edit_user_saga() {
  yield takeEvery("EDIT_USER", edit_user_saga)
}


// all_user_trombino
function* liste_users_trombino_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETUSERS}?pagination=oui&page=${action.payload.page}${action.payload.recherche && "&search=" + action.payload.recherche}${action.payload.textTeam ? "&team=" + action.payload.textTeam : ""}`,
    get,
    null,
    liste_users_trombino_action_done,
  )
}

export function* watch_liste_users_trombino_saga() {
  yield takeEvery("LISTE_USERS_TROMBINO", liste_users_trombino_saga)
}


// liste_teams
function* liste_team_saga() {
  yield call(
    callApi,
    urls.apiUrl + urls.URL_TEAMS,
    get,
    null,
    liste_team_action_done,
  )
}

export function* watch_liste_team_saga() {
  yield takeEvery("LISTE_TEAMS", liste_team_saga)
}


// liste_teams_retard
function* liste_team_retard_saga(action) {
  yield call(
    callApi,
    urls.apiUrl + urls.URL_GETTEAM + action.payload + "/?retard=True",
    get,
    null,
    liste_team_retard_action_done,
  )
}

export function* watch_liste_team_retard_saga() {
  yield takeEvery("LISTE_TEAMS_RETARD", liste_team_retard_saga)
}


// liste_teams
function* liste_user_times_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETMYTIMES}?page=${action.payload.page}${action.payload.search ? "&search=" + action.payload.search : ""}${action.payload.labelTicker ? "&ticker=" + action.payload.labelTicker : ""}${action.payload.userValue ? "&user=" + action.payload.userValue : ""}${action.payload.jour_debut ? "&jour_debut=" + action.payload.jour_debut : ""}${action.payload.jour_fin ? "&jour_fin=" + action.payload.jour_fin : ""}${action.payload.download ? "&download=oui" : ""}`,
    get,
    null,
    liste_user_times_action_done,
  )
}

export function* watch_liste_user_times_saga() {
  yield takeEvery("LISTE_USER_TIMES", liste_user_times_saga)
}


// liste_teams_download
function* liste_user_times_download_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETMYTIMES}?page=${action.payload.page}${action.payload.search ? "&search=" + action.payload.search : ""}${action.payload.labelTicker ? "&ticker=" + action.payload.labelTicker : ""}${action.payload.userValue ? "&user=" + action.payload.userValue : ""}${action.payload.jour_debut ? "&jour_debut=" + action.payload.jour_debut : ""}${action.payload.jour_fin ? "&jour_fin=" + action.payload.jour_fin : ""}${action.payload.download ? "&download=oui" : ""}`,
    get,
    null,
    liste_user_times_download_action_done,
  )
}

export function* watch_liste_user_times_download_saga() {
  yield takeEvery("LISTE_USER_TIMES_DOWNLOAD", liste_user_times_download_saga)
}

// user_stat
function* user_stat_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETSTATSBYWEEK}?time=${action.payload.type}&date=${action.payload.date}&user=${action.payload.id}`,
    get,
    null,
    user_stat_action_done
  )
}

export function* watch_user_stat_saga() {
  yield takeEvery("USER_STAT", user_stat_saga)
}


// user_date_controller
function* user_date_controller_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETSTATSCONTROLS}?time=${action.payload.type}&date=${action.payload.date}&user=${action.payload.id}`,
    get,
    null,
    user_date_controller_action_done
  )
}

export function* watch_user_date_controller_saga() {
  yield takeEvery("USER_DATE_CONTROLLER", user_date_controller_saga)
}
