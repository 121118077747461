import image from "../../static/images/error-page.png"

export default function ErrorFallback() {
  return (
    <div className="error-container">
      <img className="error-img" src={ image } />
      <h3 className="error-title">Oops ! Un problème est survenu</h3>
      <p className="error-description">Veuillez réessayer ultérieurement ou contacter l'administration si le problème persiste.</p>
      <button className="error-button" onClick={() => window.location.href = "/"}>Recharger la page</button>
    </div>
  )
}