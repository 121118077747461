import React, { Component } from "react"
import { Action, Fab } from "react-tiny-fab"
import "react-tiny-fab/dist/styles.css"
import AjouterTickerDialog from "./AjouterTickerDialog"
import AjouterFactureDialog from "./AjouterFactureDialog"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { liste_permission_action } from "../../actions/user"
import background from "../../static/images/background.png"

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ajouterTickerDialogOpen: false,
      ajouterFactureDialogOpen: false,
      displayFab: false,
      canAddTicker: false,
      canAddFacture: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { liste_permissions } = this.props

    if (prevProps.liste_permissions !== liste_permissions) {
      if (liste_permissions && liste_permissions.status === 200) {
        if (liste_permissions.data?.some(item => "valider_demande_ticker" === item.type || "view_demande_ticker" === item.type)) {
          this.setState({
            canAddTicker: true,
            displayFab: true
          })
        }

        if (liste_permissions.data?.some(item => "gestion_facture" === item.type)) {
          this.setState({
            canAddFacture: true,
            displayFab: true
          })
        }
      }
    }
  }



  render() {
    const styles = {
      backgroundImage: `url(${background})`,
      padding: '40px 0',
      marginTop: '10px',
    };
    return (
      <div id="footer" style={styles}></div>
    )

    // if (this.state.displayFab) {
    //   return (
    //     <div id="footer">
    //       <Fab
    //         alwaysShowTitle={false}
    //         icon={<i className="fas fa-plus" />}
    //         mainButtonStyles={{backgroundColor: "#57a08f", boxShadow: "0 0 3px 3px #00000033"}}
    //       >
    //         {this.state.canAddTicker ? (
    //           <Action
    //             text="Creer un ticker"
    //             style={{backgroundColor: "#1E2867"}}
    //             onClick={() => this.setState({ajouterTickerDialogOpen: true})}
    //           >
    //             <i className="fas fa-ticket-alt" />
    //           </Action>
    //         ) : null}
    //         {this.state.canAddFacture ? (
    //           <Action
    //             text="Creer une facture"
    //             style={{backgroundColor: "#1E2867"}}
    //             onClick={() => this.setState({ajouterFactureDialogOpen: true})}
    //           >
    //             <i className="fas fa-cart-plus" />
    //           </Action>
    //         ) : null}
    //       </Fab>

    //       <AjouterTickerDialog
    //         OpenDialogState={this.state.ajouterTickerDialogOpen}
    //         handleOpenDialog={() => this.setState({ajouterTickerDialogOpen: true})}
    //         handleCloseDialog={() => this.setState({ajouterTickerDialogOpen: false})}
    //       />

    //       <AjouterFactureDialog
    //         OpenDialogState={this.state.ajouterFactureDialogOpen}
    //         handleOpenDialog={() => this.setState({ajouterFactureDialogOpen: true})}
    //         handleCloseDialog={() => this.setState({ajouterFactureDialogOpen: false})}
    //       />
    //     </div>
    //   )
    // } else {
    //   return (
    //     <div id="footer"></div>
    //   )
    // }
  }
}

const mapStateToProps = (state) => {
  return {
    liste_permissions: state.user.liste_permissions,
  }
}

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    liste_permission_action: liste_permission_action,
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
