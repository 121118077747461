import { combineReducers } from "redux"
import user from "./user"
import ticker from "./ticker"
import timesheet from "./timesheet"
import facture from "./facture"
import client from "./client"
import marque from "./marque"
import fournisseur from "./fournisseur"
import conge from "./conge"
import attestation from "./attestation"
import explication from "./explication"
import nonStaffe from "./nonStaffe"
import noteFrais from "./noteFrais"
import maladie from "./maladie"

export default combineReducers({
  user: user,
  ticker: ticker,
  timesheet: timesheet,
  facture: facture,
  client: client,
  marque: marque,
  fournisseur: fournisseur,
  conge: conge,
  attestation: attestation,
  explication: explication,
  nonStaffe: nonStaffe,
  noteFrais: noteFrais,
  maladie: maladie,
})
