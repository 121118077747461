import { lazy } from "react"
import urls from "../services/urls"

const Login = lazy(() => import("../components/login/index"))
const Redirection = lazy(() => import("../components/common/redirection"))
const Timesheet = lazy(() => import("../components/timesheet/index"))
const TimesheetLegacy = lazy(() => import("../components/timesheet-legacy/index"))
const EquipeDispo = lazy(() => import("../components/users/EquipeDisponible"))
const SuiviTimesheet = lazy(() => import("../components/timesheet-legacy/SuiviTimesheet"))
const Home = lazy(() => import("../components/ticker/index"))
const DetailTicker = lazy(() => import("../components/ticker/detailTicker"))
const RapportCollaborateur = lazy(() => import("../components/users/RapportCollaborateur"))
const DetailTickerSimplifie = lazy(() => import("../components/ticker/detailTickerSimplifie"))
const TickerAlert = lazy(() => import("../components/ticker/tickerAlert"))
const StatutsTicker = lazy(() => import("../components/ticker/tickerStatut"))
const Trombinoscope = lazy(() => import("../components/users/trombinoscope"))
const MonCompte = lazy(() => import("../components/users/monCompte"))
const Clients = lazy(() => import("../components/client/index"))
const RapportClients = lazy(() => import("../components/client/RapportClient"))
const DetailClient = lazy(() => import("../components/client/DetailClient"))
const DetailClientSimplifie = lazy(() => import("../components/client/DetailClientSimplifie"))
const Marques = lazy(() => import("../components/marque/index"))
const DetailMarque = lazy(() => import("../components/marque/DetailMarque"))
const DetailMarqueSimplifie = lazy(() => import("../components/marque/DetailMarqueSimplifie"))
const Contacts = lazy(() => import("../components/client/contact"))
const Factures = lazy(() => import("../components/facture/index"))
const Facturation = lazy(() => import("../components/facture/tableauBordFacturation"))
const Fournisseur = lazy(() => import("../components/fournisseur/index"))
const FacturesFournisseur = lazy(() => import("../components/fournisseur/factureFournisseur"))
const FournisseurV2 = lazy(() => import("../components/fournisseur v2/index"))
const FacturesFournisseurV2 = lazy(() => import("../components/fournisseur v2/factureFournisseur"))
const demandeConge = lazy(() => import("../components/conge/DemandeConge"))
const conge = lazy(() => import("../components/conge"))
const solde = lazy(() => import("../components/conge/SoldeConge"))
const teamSolde = lazy(() => import("../components/conge/SoldeEquipe"))
const teamConge = lazy(() => import("../components/conge/teamConge"))
const allConge = lazy(() => import("../components/conge/allConge"))
const Attestation = lazy(() => import("../components/attestation"))
const DemandeAttestation = lazy(() => import("../components/attestation/demandeAttestation"))
const ValidationAttestation = lazy(() => import("../components/attestation/validationAttestation"))
const explication = lazy(() => import("../components/explications"))
const MesDemandesExplication = lazy(() => import("../components/explications/MesDemandes"))
const ToutesDemandesExplication = lazy(() => import("../components/explications/AllDemandes"))
const MesOrdresDeMissions = lazy(() => import("../components/noteFrais"))
const DemandesRemboursements = lazy(() => import("../components/noteFrais/DemandeRemboursement"))
const ValidationDemandesRemboursements = lazy(() => import("../components/noteFrais/ValidationDemande"))
const Mystat = lazy(() => import("../components/users/Mystat"))
const MesTickersSuperUser = lazy(() => import("../components/ticker/MesTickersSuperUser"))
const MesTickers = lazy(() => import("../components/ticker/MesTickers"))
const Maladie = lazy(() => import("../components/maladie/index"))
const AllMaladie = lazy(() => import("../components/maladie/AllMaladie"))
const DeclarationMaladie = lazy(() => import("../components/maladie/declaration"))
const MyDemande = lazy(() => import("../components/documents/MesDemandes/index"))
const ToutesDemandesAttestation = lazy(() => import("../components/documents/AllDemandes/index"))
const DashboardAttestation = lazy(() => import("../components/documents/Dashboard/index"))
const Dashboard = lazy(() => import("../components/dashboard/index"))

const routes = [
  {
    component: Login,
    path: urls.login,
  },
  {
    component: Redirection,
    path: urls.redirection,
    login: true,
  },
  {
    component: Timesheet,
    path: urls.timesheet,
    login: true,
  },
  {
    component: TimesheetLegacy,
    path: urls.timesheet + "-legacy",
    login: true,
  },
  {
    component: SuiviTimesheet,
    path: urls.suiviTimesheet,
    login: true,
  },
  {
    component: Home,
    path: urls.dashboard,
    login: true,
  },
  {
    component: MesTickers,
    path: urls.MesTickers,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: MesTickersSuperUser,
    path: urls.MesTickersSuperUser,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: DetailTicker,
    path: urls.detailTicker + "/:id/:date",
    login: true,
  },
  {
    component: DetailTickerSimplifie,
    path: urls.detailTickerSimplifie + "/:id",
    login: true,
  },
  {
    component: TickerAlert,
    path: urls.MesTickersAlert,
    login: true,
  },
  {
    component: StatutsTicker,
    path: urls.statutTicker,
    login: true,
  },
  {
    component: Trombinoscope,
    path: urls.trombinoscope,
    login: true,
  },
  {
    component: Clients,
    path: urls.clients,
    login: true,
  },
  {
    component: Marques,
    path: urls.marques,
    login: true,
  },
  {
    component: Contacts,
    path: urls.contacts,
    login: true,
  },
  {
    component: Factures,
    path: urls.facture,
    login: true,
  },
  {
    component: Facturation,
    path: urls.tableauBordFacturation,
    login: true,
  },
  {
    component: RapportClients,
    path: urls.rapportClient,
    login: true,
  },
  {
    component: Fournisseur,
    path: urls.fournisseur,
    login: true,
  },
  {
    component: FacturesFournisseur,
    path: urls.factureFournisseur,
    login: true,
  },
  // Fournisseur v2 routes
  {
    component: FournisseurV2,
    path: urls.fournisseurV2,
    login: true,
  },
  {
    component: FacturesFournisseurV2,
    path: urls.factureFournisseurV2,
    login: true,
  },
  {
    component: demandeConge,
    path: urls.demandeConge,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: conge,
    path: urls.conge,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: solde,
    path: urls.solde,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: teamConge,
    path: urls.teamConge,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: allConge,
    path: urls.allConge,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: teamSolde,
    path: urls.teamSolde,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: DetailClient,
    path: urls.detailclient + "/:id/:date",
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: DetailClientSimplifie,
    path: urls.detailClientSimplifie + "/:id",
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: DetailMarque,
    path: urls.detailmarque + "/:id/:date",
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: DetailMarqueSimplifie,
    path: urls.detailMarqueSimplifie + "/:id",
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: MonCompte,
    path: urls.moncompte,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: DemandeAttestation,
    path: urls.demandeAttestation,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: Attestation,
    path: urls.Attestation,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: ValidationAttestation,
    path: urls.ValidationAttestation,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: explication,
    path: urls.explication,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: MesDemandesExplication,
    path: urls.MesDemandesExplication,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: ToutesDemandesExplication,
    path: urls.ToutesDemandesExplication,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: EquipeDispo,
    path: urls.EquipeDispo,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: RapportCollaborateur,
    path: urls.mesTimeSheet,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: MesOrdresDeMissions,
    path: urls.OrdreDeMission,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: DemandesRemboursements,
    path: urls.DemandeRemboursement,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: ValidationDemandesRemboursements,
    path: urls.ValidationDemandeRemboursement,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: Mystat,
    path: urls.statistics + "/:id/:date",
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: Maladie,
    path: urls.Maladie,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: AllMaladie,
    path: urls.AllMaladie,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: DeclarationMaladie,
    path: urls.declarationMaladie,
    login: true,
    options: {
      exact: true,
    }
  },
  {
    component: MyDemande,
    path: urls.demande,
    login: true,
  },
  {
    component: ToutesDemandesAttestation,
    path: urls.toutesDemandesAttestation,
    login: true,
  },
  {
    component: DashboardAttestation,
    path: urls.dashboardAttestation,
    login: true,
  },
  {
    component: Dashboard,
    path: urls.dashboadAllocationFactureClient,
    login: true,
    options: {
      exact: true,
    }
  },
]


export default routes
