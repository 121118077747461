// get_types
export function get_types_action(payload) {
  return {
    type: "LIST_TYPE",
    payload,
  }
}

export function get_types_action_done(request) {
  return {
    type: "LIST_TYPE_DONE",
    payload: request,
  }
}

// add_attestation
export function add_attestation_action(payload) {
  return {
    type: "DEMANDE_ATTESTATION",
    payload,
  }
}

export function add_attestation_action_done(request) {
  return {
    type: "DEMANDE_ATTESTATION_DONE",
    payload: request,
  }
}

// validate_attestation
export function validate_attestation_action(payload) {
  return {
    type: "VALIDE_DEMANDE",
    payload,
  }
}

export function validate_attestation_action_done(request) {
  return {
    type: "VALIDE_DEMANDE_DONE",
    payload: request,
  }
}

// get_attestation
export function get_attestation_action(payload) {
  return {
    type: "LIST_ATTESTATION",
    payload,
  }
}

export function get_attestation_action_done(request) {
  return {
    type: "LIST_ATTESTATION_DONE",
    payload: request,
  }
}

// get_attestation_cours
export function get_attestation_cours_action(payload) {
  return {
    type: "LIST_ATTESTATION_EN_COURS_SIGNATURE",
    payload,
  }
}

export function get_attestation_cours_action_done(request) {
  return {
    type: "LIST_ATTESTATION_EN_COURS_SIGNATURE_DONE",
    payload: request,
  }
}

// get_attestation_signe
export function get_attestation_signe_action(payload) {
  return {
    type: "LIST_ATTESTATION_SIGNE",
    payload,
  }
}

export function get_attestation_signe_action_done(request) {
  return {
    type: "LIST_ATTESTATION_SIGNE_DONE",
    payload: request,
  }
}

// get_all_attestation
export function get_all_attestation_action(payload) {
  return {
    type: "LIST_ALL_ATTESTATION",
    payload,
  }
}

export function get_all_attestation_action_done(request) {
  return {
    type: "LIST_ALL_ATTESTATION_DONE",
    payload: request,
  }
}

// get_all_attestation_cours
export function get_all_attestation_cours_action(payload) {
  return {
    type: "LIST_ALL_ATTESTATION_EN_COURS_SIGNATURE",
    payload,
  }
}

export function get_all_attestation_cours_action_done(request) {
  return {
    type: "LIST_ALL_ATTESTATION_EN_COURS_SIGNATURE_DONE",
    payload: request,
  }
}

// get_all_attestation_signe
export function get_all_attestation_signe_action(payload) {
  return {
    type: "LIST_ALL_ATTESTATION_SIGNE",
    payload,
  }
}

export function get_all_attestation_signe_action_done(request) {
  return {
    type: "LIST_ALL_ATTESTATION_SIGNE_DONE",
    payload: request,
  }
}
