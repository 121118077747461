export const initialState = {
  next_day: {},
  day_before: {},
  ticker_admin: [],
  ticker_saved: [],
  Liste_suivi_timesheet: [],
  timesheet_published: {},

  // Timesheet client state
  selectedTickers: []
}

const timesheet = (state = initialState, action) => {
  switch (action.type) {
  case "NEXT_DAY_DONE":
    return {
      ...state,
      next_day: action.payload,
    }
  case "DAY_BEFORE_DONE":
    return {
      ...state,
      day_before: action.payload,
    }
  case "TICKER_ADMIN_DONE":
    return {
      ...state,
      ticker_admin: action.payload,
    }
  case "TICKER_SAVED_DONE":
    return {
      ...state,
      ticker_saved: action.payload,
    }
  case "PUBLISH_TIMESHEET_DONE":
    return {
      ...state,
      timesheet_published: action.payload,
    }
  case "SUIVI_TIMESHEET_DONE":
    return {
      ...state,
      Liste_suivi_timesheet: action.payload,
    }

  // Timesheet client state
  case "SET_SELECTED_TICKERS":
    return {
      ...state,
      selectedTickers: action.payload,
    }
  default:
    return state
  }
}

export default timesheet
