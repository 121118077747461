// get_ordre_mission
export function get_ordre_mission_action(payload) {
  return {
    type: "LIST_ORDRES_MISSIONS",
    payload,
  }
}

export function get_ordre_mission_action_done(request) {
  return {
    type: "LIST_ORDRES_MISSIONS_DONE",
    payload: request,
  }
}

// get_remboursement
export function get_remboursement_action(payload) {
  return {
    type: "LIST_REMBOURSEMENT",
    payload,
  }
}

export function get_remboursement_action_done(request) {
  return {
    type: "LIST_REMBOURSEMENT_DONE",
    payload: request,
  }
}

// get_remboursement_valide
export function get_remboursement_valide_action(payload) {
  return {
    type: "LIST_REMBOURSEMENT_VALIDE",
    payload,
  }
}

export function get_remboursement_valide_action_done(request) {
  return {
    type: "LIST_REMBOURSEMENT_VALIDE_DONE",
    payload: request,
  }
}

// get_remboursement_rembourse
export function get_remboursement_rembourse_action(payload) {
  return {
    type: "LIST_REMBOURSEMENT_REMBOURSE",
    payload,
  }
}

export function get_remboursement_rembourse_action_done(request) {
  return {
    type: "LIST_REMBOURSEMENT_REMBOURSE_DONE",
    payload: request,
  }
}

// get_remboursement_rejete
export function get_remboursement_rejete_action(payload) {
  return {
    type: "LIST_REMBOURSEMENT_REJETE",
    payload,
  }
}

export function get_remboursement_rejete_action_done(request) {
  return {
    type: "LIST_REMBOURSEMENT_REJETE_DONE",
    payload: request,
  }
}

// get_moyen_paiement
export function get_moyen_paiement_action(payload) {
  return {
    type: "MOYEN_PAIEMNT",
    payload,
  }
}

export function get_moyen_paiement_action_done(request) {
  return {
    type: "MOYEN_PAIEMNT_DONE",
    payload: request,
  }
}

// get_moyen_remboursement
export function get_moyen_remboursement_action(payload) {
  return {
    type: "MOYEN_REMBOURSEMENT",
    payload,
  }
}

export function get_moyen_remboursement_action_done(request) {
  return {
    type: "MOYEN_REMBOURSEMENT_DONE",
    payload: request,
  }
}

// categorie_remboursement
export function categorie_remboursement_action(payload) {
  return {
    type: "CATEGORIE_REMBOURSEMENT",
    payload,
  }
}

export function categorie_remboursement_action_done(request) {
  return {
    type: "CATEGORIE_REMBOURSEMENT_DONE",
    payload: request,
  }
}

// statut_ordre
export function statut_ordre_action(payload) {
  return {
    type: "STATUT_ORDRE",
    payload,
  }
}

export function statut_ordre_action_done(request) {
  return {
    type: "STATUT_ORDRE_DONE",
    payload: request,
  }
}

// statut_remboursement
export function statut_remboursement_action(payload) {
  return {
    type: "STATUT_REMBOURSEMENT",
    payload,
  }
}

export function statut_remboursement_action_done(request) {
  return {
    type: "STATUT_REMBOURSEMENT_DONE",
    payload: request,
  }
}

// create_order
export function create_order_action(payload) {
  return {
    type: "CREATE_ORDER",
    payload,
  }
}

export function create_order_action_done(request) {
  return {
    type: "CREATE_ORDER_DONE",
    payload: request,
  }
}

// update_order
export function update_order_action(payload) {
  return {
    type: "UPDATE_ORDER",
    payload,
  }
}

export function update_order_action_done(request) {
  return {
    type: "UPDATE_ORDER_DONE",
    payload: request,
  }
}

// delete_order
export function delete_order_action(payload) {
  return {
    type: "DELETE_ORDER",
    payload,
  }
}

export function delete_order_action_done(request) {
  return {
    type: "DELETE_ORDER_DONE",
    payload: request,
  }
}

// create_refund
export function create_refund_action(payload) {
  return {
    type: "CREATE_REFUND",
    payload,
  }
}

export function create_refund_action_done(request) {
  return {
    type: "CREATE_REFUND_DONE",
    payload: request,
  }
}

// update_refund
export function update_refund_action(payload) {
  return {
    type: "UPDATE_REFUND",
    payload,
  }
}

export function update_refund_action_done(request) {
  return {
    type: "UPDATE_REFUND_DONE",
    payload: request,
  }
}

// delete_refund
export function delete_refund_action(payload) {
  return {
    type: "DELETE_REFUND",
    payload,
  }
}

export function delete_refund_action_done(request) {
  return {
    type: "DELETE_REFUND_DONE",
    payload: request,
  }
}
