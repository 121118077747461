// Liste fournisseur
export function liste_fournisseur_action(payload) {
  return {
    type: "LISTE_FOURNISSEUR",
    payload,
  }
}

export function liste_fournisseur_action_done(request) {
  return {
    type: "LISTE_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Liste fournisseur all
export function liste_fournisseur_all_action(payload) {
  return {
    type: "LISTE_FOURNISSEUR_ALL",
    payload,
  }
}

export function liste_fournisseur_all_action_done(request) {
  return {
    type: "LISTE_FOURNISSEUR_ALL_DONE",
    payload: request,
  }
}

// Info fournisseur
export function info_fournisseur_action(payload) {
  return {
    type: "INFO_FOURNISSEUR",
    payload,
  }
}

export function info_fournisseur_action_done(request) {
  return {
    type: "INFO_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Add fournisseur
export function add_fournisseur_action(payload) {
  return {
    type: "ADD_FOURNISSEUR",
    payload,
  }
}

export function add_fournisseur_action_done(request) {
  return {
    type: "ADD_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Update fournisseur
export function update_fournisseur_action(payload) {
  return {
    type: "UPDATE_FOURNISSEUR",
    payload,
  }
}

export function update_fournisseur_action_done(request) {
  return {
    type: "UPDATE_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Liste custom values fournisseur
export function liste_custom_values_fournisseur_action(payload) {
  return {
    type: "CUSTOM_VALUES_FOURNISSEUR",
    payload,
  }
}

export function liste_custom_values_fournisseur_action_done(request) {
  return {
    type: "CUSTOM_VALUES_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Liste contact fournisseur
export function liste_contact_fournisseur_action(payload) {
  return {
    type: "CONTACT_FOURNISSEUR",
    payload,
  }
}

export function liste_contact_fournisseur_action_done(request) {
  return {
    type: "CONTACT_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Liste files fournisseur
export function liste_files_fournisseur_action(payload) {
  return {
    type: "FILES_FOURNISSEUR",
    payload,
  }
}

export function liste_files_fournisseur_action_done(request) {
  return {
    type: "FILES_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Add files fournisseur
export function add_files_fournisseur_action(payload) {
  return {
    type: "ADD_FILES_FOURNISSEUR",
    payload,
  }
}

export function add_files_fournisseur_action_done(request) {
  return {
    type: "ADD_FILES_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Add contacts fournisseur
export function add_contacts_fournisseur_action(payload) {
  return {
    type: "ADD_CONTACTS_FOURNISSEUR",
    payload,
  }
}

export function add_contacts_fournisseur_action_done(request) {
  return {
    type: "ADD_CONTACTS_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Update files fournisseur
export function update_files_fournisseur_action(payload) {
  return {
    type: "UPDATE_FILES_FOURNISSEUR",
    payload,
  }
}

export function update_files_fournisseur_action_done(request) {
  return {
    type: "UPDATE_FILES_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Liste categorie fournisseur
export function liste_categorie_fournisseur_action(payload) {
  return {
    type: "CATEGORIE_FOURNISSEUR",
    payload,
  }
}

export function liste_categorie_fournisseur_action_done(request) {
  return {
    type: "CATEGORIE_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Liste Sous categorie fournisseur
export function liste_sous_categorie_fournisseur_action(payload) {
  return {
    type: "SOUS_CATEGORIE_FOURNISSEUR",
    payload,
  }
}

export function liste_sous_categorie_fournisseur_action_done(request) {
  return {
    type: "SOUS_CATEGORIE_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Liste custom values
export function liste_custom_values_action(payload) {
  return {
    type: "CUSTOM_VALUES",
    payload,
  }
}

export function liste_custom_values_action_done(request) {
  return {
    type: "CUSTOM_VALUES_DONE",
    payload: request,
  }
}

// Liste factures fournisseur
export function liste_factures_fournisseur_action(payload) {
  return {
    type: "LISTE_FACTURE_FOURNISSEUR",
    payload,
  }
}

export function liste_factures_fournisseur_action_done(request) {
  return {
    type: "LISTE_FACTURE_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Add facture fournisseur
export function add_facture_fournisseur_action(payload) {
  return {
    type: "ADD_FACTURE_FOURNISSEUR",
    payload,
  }
}

export function add_facture_fournisseur_action_done(request) {
  return {
    type: "ADD_FACTURE_FOURNISSEUR_DONE",
    payload: request,
  }
}

// update facture fournisseur
export function update_facture_fournisseur_action(payload) {
  return {
    type: "UPDATE_FACTURE_FOURNISSEUR",
    payload,
  }
}

export function update_facture_fournisseur_action_done(request) {
  return {
    type: "UPDATE_FACTURE_FOURNISSEUR_DONE",
    payload: request,
  }
}


// liste_allocation_offre_facture_fournisseur
export function liste_allocation_offre_facture_fournisseur_action(payload) {
  return {
    type: "ALLOCATION_OFFRE_FACTURE_FOURNISSEUR",
    payload,
  }
}

export function liste_allocation_offre_facture_fournisseur_action_done(request) {
  return {
    type: "ALLOCATION_OFFRE_FACTURE_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Liste Offre allocation fournisseur
export function liste_allocation_offre_fournisseur_action(payload) {
  return {
    type: "ALLOCATION_OFFRE_FOURNISSEUR",
    payload,
  }
}

export function liste_allocation_offre_fournisseur_action_done(request) {
  return {
    type: "ALLOCATION_OFFRE_FOURNISSEUR_DONE",
    payload: request,
  }
}

// Statut facture
export function statut_facture_fournisseur_action(payload) {
  return {
    type: "STATUT_FACTURE_FOURNISSEUR",
    payload,
  }
}

export function statut_facture_fournisseur_action_done(request) {
  return {
    type: "STATUT_FACTURE_FOURNISSEUR_DONE",
    payload: request,
  }
}
