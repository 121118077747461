export const initialState = {
  liste_clients: [],
  liste_contacts: [],
  rapport_client: {},
  detail_client: {},
  client_added: {},
  client_updated: {},
  client_controller: {},
  client_stat: {},
  allocation_client: {},
  depense_client: {},
  facture_client: {},
  ticker_client: [],
  // marques_client : {},
}

const client = (state = {}, action) => {
  switch (action.type) {
  case "LISTE_CLIENTS_DONE":
    return {
      ...state,
      liste_clients: action.payload,
    }
  case "ADD_CLIENT_DONE":
    return {
      ...state,
      client_added: action.payload,
    }
  case "LISTE_CONTACTS_DONE":
    return {
      ...state,
      liste_contacts: action.payload,
    }
  case "RAPPORT_CLIENT_DONE":
    return {
      ...state,
      rapport_client: action.payload,
    }
  case "INFO_CLIENT_DONE":
    return {
      ...state,
      detail_client: action.payload,
    }
  case "UPDATE_CLIENT_DONE":
    return {
      ...state,
      client_updated: action.payload,
    }
  case "CLIENT_DATE_CONTROLLER_DONE":
    return {
      ...state,
      client_controller: action.payload,
    }
  case "CLIENT_USER_STAT_DONE":
    return {
      ...state,
      client_stat: action.payload,
    }
  case "ALLOCATION_CLIENT_DONE":
    return {
      ...state,
      allocation_client: action.payload,
    }
  case "DEPENSE_CLIENT_DONE":
    return {
      ...state,
      depense_client: action.payload,
    }
  case "FACTURE_CLIENT_DONE":
    return {
      ...state,
      facture_client: action.payload,
    }
  case "TICKER_CLIENT_DONE":
    return {
      ...state,
      ticker_client: action.payload,
    }
  default:
    return state
  }
}

export default client
