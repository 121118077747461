import { call, takeEvery } from "redux-saga/effects"
import {
  add_marque_action_done,
  allocation_marque_action_done,
  depense_marque_action_done,
  facture_marque_action_done,
  info_marque_action_done,
  liste_marques_action_done,
  marque_date_controller_action_done,
  marque_user_stat_action_done,
  update_marque_action_done
} from "../actions/marque"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, patch, post } from "../services/request"


// liste_marques
function* liste_marques_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETLISTEMARQUE}?page=${action.payload.page}${action.payload.textRecherche && "&search=" + action.payload.textRecherche}`,
    get,
    null,
    liste_marques_action_done
  )
}

export function* watch_liste_marques_saga() {
  yield takeEvery("LISTE_MARQUES", liste_marques_saga)
}


// add_marque
function* add_marque_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETLISTEMARQUE}`,
    post,
    action.payload,
    add_marque_action_done
  )
}

export function* watch_add_marque_saga() {
  yield takeEvery("ADD_MARQUE", add_marque_saga)
}


// deatil_marque
function* detail_marque_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_DETAILMARQUE}${action.payload.id}/`,
    get,
    null,
    info_marque_action_done
  )
}

export function* watch_detail_marque_saga() {
  yield takeEvery("INFO_MARQUE", detail_marque_saga)
}

// update_marque
function* update_marque_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATEMARQUE}${action.payload.id}/`,
    patch,
    action.payload.data,
    update_marque_action_done
  )
}

export function* watch_update_marque_saga() {
  yield takeEvery("UPDATE_MARQUE", update_marque_saga)
}

// marque_user_stat
function* marque_user_stat_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETSTATSBYWEEK}?time=${action.payload.type}&date=${action.payload.date}&marque=${action.payload.id}${action.payload.typeMarque === "ouvert" ? "&ouvert=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.year ? "&year=" + action.payload.year : ""}${action.payload.typeMarque === "ouvert" && action.payload.trimesterMarque ? "&trimester=" + action.payload.trimesterMarque : ""}${action.payload.typeMarque === "ouvert" && action.payload.ActifFilter ? "&actif=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.PassifFilter ? "&passif=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.ArchivedFilter ? "&archive=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.typeMarque === "mensuel" ? "&mensuel=True" : ""}${action.payload.displayerTeam && action.payload.displayerTeam.length > 0 ? "&team=[" + action.payload.displayerTeam + "]" : ""}`,
    get,
    null,
    marque_user_stat_action_done
  )
}

export function* watch_marque_user_stat_saga() {
  yield takeEvery("MARQUE_USER_STAT", marque_user_stat_saga)
}


// marque_date_controller
function* marque_date_controller_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETSTATSCONTROLS}?time=${action.payload.type}&date=${action.payload.date}&marque=${action.payload.id}`,
    get,
    null,
    marque_date_controller_action_done
  )
}

export function* watch_marque_date_controller_saga() {
  yield takeEvery("MARQUE_DATE_CONTROLLER", marque_date_controller_saga)
}


// depense_ticker
function* depense_marque_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_DEPENSES}?marque=${action.payload.id}&date=${action.payload.date}${action.payload.typeMarque === "ouvert" ? "&ouvert=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.year ? "&year=" + action.payload.year : ""}${action.payload.typeMarque === "ouvert" && action.payload.trimesterMarque ? "&trimester=" + action.payload.trimesterMarque : ""}${action.payload.typeMarque === "ouvert" && action.payload.ActifFilter ? "&actif=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.PassifFilter ? "&passif=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.ArchivedFilter ? "&archive=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.typeMarque === "mensuel" ? "&mensuel=True" : ""}`,
    get,
    null,
    depense_marque_action_done
  )
}

export function* watch_depense_marque_saga() {
  yield takeEvery("DEPENSE_MARQUE", depense_marque_saga)
}

// allocation_marque
function* allocation_marque_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LISTEALLOCATIONS}?detail=True&date=${action.payload.date}&marque=${action.payload.id}${action.payload.typeMarque === "ouvert" ? "&ouvert=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.year ? "&year=" + action.payload.year : ""}${action.payload.typeMarque === "ouvert" && action.payload.trimesterMarque ? "&trimester=" + action.payload.trimesterMarque : ""}${action.payload.typeMarque === "ouvert" && action.payload.ActifFilter ? "&actif=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.PassifFilter ? "&passif=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.ArchivedFilter ? "&archive=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.typeMarque === "mensuel" ? "&mensuel=True" : ""}`,
    get,
    null,
    allocation_marque_action_done
  )
}

export function* watch_allocation_marque_saga() {
  yield takeEvery("ALLOCATION_MARQUE", allocation_marque_saga)
}

// facture_marque
function* facture_marque_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_FACTURESCLIENT}?date=${action.payload.date}${action.payload.typeMarque === "ouvert" ? "&ouvert=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.ActifFilter ? "&actif=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.PassifFilter ? "&passif=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.ArchivedFilter ? "&archive=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.typeMarque === "ouvert" && action.payload.dateTicker ? "&year=" + action.payload.year : ""}${action.payload.typeMarque === "ouvert" && action.payload.trimesterMarque ? "&trimester=" + action.payload.trimesterMarque : ""}${action.payload.typeMarque === "mensuel" ? "&mensuel=True" : ""}&marque=${action.payload.id}${action.payload.future ? "&future=True" : ""}${action.payload.facture ? "&facture=True" : ""}${action.payload.lead ? "&lead=True" : ""}${action.payload.abandonne ? "&abandonne=True" : ""}${action.payload.virtuelle ? "&virtuelle=True" : ""}${action.payload.afacturer ? "&en_attente=True" : ""}`,
    get,
    null,
    facture_marque_action_done
  )
}

export function* watch_facture_marque_saga() {
  yield takeEvery("FACTURE_MARQUE", facture_marque_saga)
}
