import { Suspense } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import routes from "./services/routes"
import urls from "./services/urls"
import Cookies from "universal-cookie"
import SpinnerPage from "./components/common/SpinnerPage"
import "./App.css"
import Footer from "./components/common/footer"
import "moment/locale/fr"
import theme from "./config/theme"
import { ThemeProvider } from "@mui/material"
import { ErrorBoundary } from "@sentry/react"
import { Toaster } from "react-hot-toast"
import ErrorFallback from "./components/common/ErrorFallback.js"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import queryConfig from "./config/queryConfig"
import { LocalizationProvider } from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"


const cookies = new Cookies()

// const Error404 = lazy( () => import('./components/common/Error404') )


function App() {
  return (
    <QueryClientProvider client={queryConfig}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="App">
              <Suspense fallback={<SpinnerPage />}>
                <Routes>
                  {
                    routes.map((route, index) => {
                      if (route.login && !cookies.get("access_token")) {
                        return <Route key={`route-${index}`} path="*" element={<Navigate to={urls.login} replace />} />
                      } else {
                        return <Route key={`route-${index}`} path={route.path} element={<route.component />} />
                      }
                    }, this)
                  }
                  <Route path="/front/*" element={cookies.get("access_token") ? <Navigate to={location.pathname.replace("/front", "")} /> : <Navigate to={urls.login} />} />
                  <Route exact path="/" element={cookies.get("access_token") ? <Navigate to={urls.redirection} /> : <Navigate to={urls.login} />} />
                </Routes>
              </Suspense>
              <Footer />
              <Toaster
                position="bottom-center"
                duration= {5000}
                toastOptions={{
                  style: { backgroundColor: "#232323", color: "#fafafa" }
                }}
              />
            </div>
          </LocalizationProvider>
        </ThemeProvider>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  )
}

export default App
