export const initialState = {
  login: {},
  liste_permissions: [],
  liste_users_trombino: [],
  liste_team: [],
  liste_team_retard: [],
  user_times: [],
  user_times_download: [],
  user_info: {},
  user_edited: {},
  user_controller: {},
  user_stat: {},
}

const user = (state = {}, action) => {
  switch (action.type) {
  case "LOGIN_DONE":
    return {
      ...state,
      login: action.payload,
    }
  case "LISTE_PERMISSION_DONE":
    return {
      ...state,
      liste_permissions: action.payload,
    }

  case "USER_INFO_DONE":
    return {
      ...state,
      user_info: action.payload,
    }
  case "EDIT_USER_DONE":
    return {
      ...state,
      user_edited: action.payload,
    }
  case "LISTE_USERS_TROMBINO_DONE":
    return {
      ...state,
      liste_users_trombino: action.payload,
    }
  case "LISTE_TEAMS_DONE":
    return {
      ...state,
      liste_team: action.payload,
    }
  case "LISTE_TEAMS_RETARD_DONE":
    return {
      ...state,
      liste_team_retard: action.payload,
    }
  case "LISTE_USER_TIMES_DONE":
    return {
      ...state,
      user_times: action.payload,
    }
  case "LISTE_USER_TIMES_DOWNLOAD_DONE":
    return {
      ...state,
      user_times_download: action.payload,
    }
  case "USER_DATE_CONTROLLER_DONE":
    return {
      ...state,
      user_controller: action.payload,
    }
  case "USER_STAT_DONE":
    return {
      ...state,
      user_stat: action.payload,
    }
  default:
    return state
  }
}

export default user
