import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import "react-datepicker/dist/react-datepicker-cssmodules.css"
import moment from "moment"
import CircularProgress from "@mui/material/CircularProgress"
import {
  add_facture_action,
  entite_action,
  liste_allocation_offre_action,
  revenue_liste_action,
  statut_facture_action
} from "../../actions/facture"
import { liste_am_action, liste_tickers_search_action, liste_tickers_search_value_action } from "../../actions/ticker"
import swal from "sweetalert2/dist/sweetalert2.all.min.js"
import withReactContent from "sweetalert2-react-content"
import Formbutton from "./ButtonForm"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DatePicker from "@mui/lab/DatePicker"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import urls from "../../services/urls"
import "../facture/facture.css"
import { toBase64 } from "../../utils/fileUtils"

moment.locale("fr")

const MySwal = withReactContent(swal)
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

class AjouterFactureDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      endRegister: "",
      errorRegister: "",
      titre: "Créer une facture ",
      ListStatut: [],
      ListEntite: [],
      allocationList: [],
      revenueList: [],
      amList: [],
      tickerList: [],
      ListAllocationsAdded: [{
        tickerlabel: "",
        ticker: "",
        allocationlabel: "",
        revenu: 100,
        allocation_offre: "",
        montant: "",
        date: null
      }],
      ListAllocationsAddedSubmit: [{
        tickerlabel: "",
        ticker: "",
        allocationlabel: "",
        revenu: 100,
        allocation_offre: "",
        montant: "",
        date: null
      }],
      errorListAllocationsAdded: [{
        errorTicker: "",
        errorrevenu: "",
        errorAllocation_offre: "",
        errorMontant: "",
        errorDate: ""
      }],
      statut: "",
      amValue: "",
      devisFile: null,
      factureFile: null,
      Referencefile: null,
      ReferenceBC: "",
      devis: "",
      numero_facture: "",
      amDisplay: "",
      description: "",
      montantTotal: "",
      montantRestant: 0,
      montantPaye: "",
      montantRestantPaye: 0,
      dateFacture: null,
      dateAccuse: null,
      fichierScanne: null,
      datePrePaiement: null,
      attestationExo: null,
      BonLivraison: null,
      montantCompense: null,
      commentaireAvoir: null,
      commentaire: null,
      entiteFacturation: "",
      errorStatut: "",
      errorAmValue: "",
      errorDevis: "",
      errorFacture: "",
      errorDescription: "",
      errorMontantTotal: "",
      errorMontantRestant: "",
      errorMontantPaye: "",
      errorMontantRestantPaye: "",
      errorDateFacture: "",
      errorDateAccuse: "",
      errorReferenceBC: "",
      errorEntiteFacturation: "",
      errordatePrePaiement: "",
      errorattestationExo: "",
      errorBonLivraison: "",
      errormontantCompense: "",
      errorcommentaireAvoir: "",
      errorcommentaire: "",
      tickerStatut: [{ type: "ouvert" }],
      disabled: false,
      user: {},
      open: [],
    }
    this.resetForm = this.resetForm.bind(this)
  }

  changeOpen(bol, index) {
    const array = [...this.state.open]
    array[index] = bol
    this.setState({
      open: array,
    })
  }

  componentDidMount() {
    this.props.liste_allocation_offre_action()
    this.props.entite_action()
    this.props.statut_facture_action()
    this.props.revenue_liste_action()
    this.props.liste_am_action()
    // this.props.liste_tickers_search_action()

    this.setState({
      amValue: this.props.user_info.data.id || "",
      amDisplay: this.props.user_info.data || "",
    })
  }

  componentDidUpdate(prevProps) {
    const {
      entite,
      statut_facture,
      revenue,
      allocation_offre,
      liste_am,
      liste_tickers_search_value,
      user_info,
      add_facture,
    } = this.props

    if (prevProps.statut_facture !== statut_facture) {
      if (statut_facture && statut_facture.status === 200) {
        this.setState({
          ListStatut: statut_facture.data
        }, () => {
          if (this.getNomstatus(this.state.statut) === "Facturée") {
            this.setState({ disabledFacture: true })
          }
        })
      }
    }

    if (prevProps.entite !== entite) {
      if (entite && entite.status === 200) {
        this.setState({
          ListEntite: entite.data
        })
      }
    }

    if (prevProps.revenue !== revenue) {
      if (revenue && revenue.status === 200) {
        this.setState({
          revenueList: revenue.data
        })
      }
    }

    if (prevProps.liste_tickers_search_value !== liste_tickers_search_value) {
      if (liste_tickers_search_value && liste_tickers_search_value.status === 200) {
        this.setState({
          tickerList: liste_tickers_search_value.data,
        })
      }
    }

    if (prevProps.allocation_offre !== allocation_offre) {
      if (allocation_offre && allocation_offre.status === 200) {
        this.setState({
          allocationList: allocation_offre.data
        })
      }
    }

    if (prevProps.liste_am !== liste_am) {
      if (liste_am && liste_am.status === 200) {
        this.setState({
          amList: liste_am.data
        })
      }
    }

    if (prevProps.user_info !== user_info) {
      if (user_info && user_info.status === 200) {
        if (user_info.data.am) {
          this.setState({
            amValue: user_info.data.id,
            amDisplay: user_info.data.user.first_name + " " + user_info.data.user.last_name,
          })
        }
      }
    }

    // handle after a facture is submitted successfully
    if (prevProps.add_facture !== add_facture) {
      if (add_facture && add_facture.status === 201) {
        this.props.handleCloseDialog()
        MySwal.fire({
          title: <strong>Facture ajoutée avec succès</strong>,
          icon: "success"
        }).then(
          () => {
            window.location.href = `${urls.facture}`
          }
        )
      }
    }
  }

  disabledBtn() {
    // this.setState({disabled:true});
    this.validateForm()
  }

  getNomstatus(id) {
    if (id) {
      const status = this.state.ListStatut.filter(element => parseInt(element.id) === parseInt(id))
      return status[0].nom
    }
  }

  validateForm(event) {
    try {
      let valideForm = true

      if (this.state.statut == "") {
        this.setState({ errorStatut: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ errorStatut: "" })
      }

      if (this.state.amValue == "") {
        this.setState({ errorAmValue: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ errorAmValue: "" })
      }

      if (this.state.description == "") {
        this.setState({ errorDescription: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ errorDescription: "" })
      }

      if (this.getNomstatus(this.state.statut) !== "Virtuelle") {
        if (this.state.montantTotal == "") {
          this.setState({ errorMontantTotal: "Ce champ est obligatoire" })
          valideForm = false
        } else {
          this.setState({ errorMontantTotal: "" })
        }
      }

      if (this.state.dateFacture == "" || this.state.dateFacture == null) {
        this.setState({ errorDateFacture: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ errorDateFacture: "" })
      }

      /* if (this.getNomstatus(this.state.statut) === "Future" || this.getNomstatus(this.state.statut) === "Facturée" || this.getNomstatus(this.state.statut) === "A Facturer"  ){
          if (this.state.devis == "") {
              this.setState({ errorDevis: "No Devis est obligatoire" });
              valideForm = false;
          } else {
              this.setState({ errorDevis: "" });
          }
      }*/

      this.state.ListAllocationsAdded.map((allocation, index) => {
        let table = this.state.errorListAllocationsAdded

        if (allocation.ticker == "") {
          table[index].errorTicker = "Ce champ est obligatoire"
          valideForm = false
        } else {
          table[index].errorTicker = ""
        }

        if (allocation.allocation_offre == "") {
          table[index].errorAllocation_offre = "Ce champ est obligatoire"
          valideForm = false
        } else {
          table[index].errorAllocation_offre = ""
        }

        if (allocation.montant == "") {
          table[index].errorMontant = "Ce champ est obligatoire"
          valideForm = false
        } else {
          table[index].errorMontant = ""
        }

        if (allocation.revenu == "") {
          table[index].errorrevenu = "Ce champ est obligatoire"
          valideForm = false
        } else {
          table[index].errorrevenu = ""
        }

        if (this.state.tickerStatut[index].type === "mensuel") {
          if (allocation.date == "" || allocation.date == null) {
            table[index].errorDate = "Ce champ est obligatoire"
            valideForm = false
          } else {
            table[index].errorDate = ""
          }
        }

        return this.setState({ errorListAllocationsAdded: table })
      })

      if (this.getNomstatus(this.state.statut) === "Facturée") {
        if (this.state.Referencefile == null) {
          this.setState({ errorReferenceBC: "Document BC est obligatoire" })
          valideForm = false
        } else {
          this.setState({ errorReferenceBC: "" })
        }

        if (this.state.datePrePaiement == "" || this.state.datePrePaiement == null) {
          this.setState({ errordatePrePaiement: "Ce champ est obligatoire" })
          valideForm = false
        } else {
          this.setState({ errordatePrePaiement: "" })
        }

        if (this.state.numero_facture == "" && this.state.factureFile == null) {
          this.setState({ errorFacture: "No Facture et le document sont obligatoire" })
          valideForm = false
        } else {
          if (this.state.factureFile == null) {
            this.setState({ errorFacture: "le document facture est obligatoire" })
            valideForm = false
          } else {
            if (this.state.numero_facture == "") {
              this.setState({ errorFacture: "No Facture est obligatoire" })
              valideForm = false
            } else {
              this.setState({ errorFacture: "" })
            }
          }
        }

        if (this.state.entiteFacturation == "") {
          this.setState({ errorEntiteFacturation: "Ce champ est obligatoire" })
          valideForm = false
        } else {
          this.setState({ errorEntiteFacturation: "" })
        }
      }

      if (this.getNomstatus(this.state.statut) === "A Facturer") {
        if (this.state.Referencefile == null) {
          this.setState({ errorReferenceBC: "Document BC est obligatoire" })
          valideForm = false
        } else {
          this.setState({ errorReferenceBC: "" })
        }

        if (this.state.numero_facture == "") {
          this.setState({ errorFacture: "No Facture est obligatoire" })
          valideForm = false
        } else {
          this.setState({ errorFacture: "" })
        }

        if (this.state.entiteFacturation == "") {
          this.setState({ errorEntiteFacturation: "Ce champ est obligatoire" })
          valideForm = false
        } else {
          this.setState({ errorEntiteFacturation: "" })
        }
      }

      if (valideForm) {
        const table2 = this.state.ListAllocationsAddedSubmit

        table2.map((allocation, index) => {
          if (allocation.tickerlabel.type !== "mensuel") {
            delete table2[index].date
          }

          delete table2[index].tickerlabel
          delete table2[index].allocationlabel
          return null
        })

        // Append form data
        const formData = new FormData()
        const formDataJson = {}

        const base64Promises = []

        if (this.state.devisFile) {
          formData.append("doc_devis", this.state.devisFile)
          base64Promises.push(
            toBase64(this.state.devisFile).then(file => formDataJson.doc_devis = file)
          )
        }

        if (this.state.Referencefile) {
          formData.append("doc_bc", this.state.Referencefile)
          base64Promises.push(
            toBase64(this.state.Referencefile).then(file => formDataJson.doc_bc = file)
          )
        }

        if (this.state.factureFile) {
          formData.append("doc_facture", this.state.factureFile)
          base64Promises.push(
            toBase64(this.state.factureFile).then(file => formDataJson.doc_facture = file)
          )
        }

        if (this.state.fichierScanne) {
          formData.append("accuse_reception", this.state.fichierScanne)
          base64Promises.push(
            toBase64(this.state.fichierScanne).then(file => formDataJson.accuse_reception = file)
          )
        }

        if (this.state.attestationExo) {
          formData.append("attestation", this.state.attestationExo)
          base64Promises.push(
            toBase64(this.state.attestationExo).then(file => formDataJson.attestation = file)
          )
        }

        if (this.state.BonLivraison) {
          formData.append("bon_livraison", this.state.BonLivraison)
          base64Promises.push(
            toBase64(this.state.BonLivraison).then(file => formDataJson.bon_livraison = file)
          )
        }

        if (this.state.dateAccuse !== "" && this.state.dateAccuse !== null) {
          formData.append("date_accuse", moment(this.state.dateAccuse).format("YYYY-MM-DD[T]HH:mm"))
          formDataJson.date_accuse = moment(this.state.dateAccuse).format("YYYY-MM-DD[T]HH:mm")
        } else {
          formData.append("date_accuse", null)
          formDataJson.date_accuse = null
        }

        if (this.state.datePrePaiement !== "" && this.state.datePrePaiement !== null) {
          formData.append("date_exigibilite", moment(this.state.datePrePaiement).format("YYYY-MM-DD[T]HH:mm"))
          formDataJson.date_exigibilite = moment(this.state.datePrePaiement).format("YYYY-MM-DD[T]HH:mm")
        }

        if (this.state.entiteFacturation !== "") {
          formData.append("entite", parseInt(this.state.entiteFacturation))
          formDataJson.entite = parseInt(this.state.entiteFacturation)
        }

        if (this.state.montantPaye !== "") {
          formData.append("montant_paye", this.state.montantPaye)
          formDataJson.montant_paye = this.state.montantPaye
        }

        formData.append("montant_restant", this.state.montantRestant)
        formData.append("montant_avoir", this.state.montantCompense)
        formData.append("numero_devis", this.state.devis)
        formData.append("statut", this.state.statut)
        formData.append("date_facture", moment(this.state.dateFacture).format("YYYY-MM-DD[T]HH:mm"))
        formData.append("montant_total", this.state.montantTotal)
        formData.append("am", this.state.amValue)
        formData.append("ref_bc", this.state.ReferenceBC)
        formData.append("numero_facture", this.state.numero_facture)
        formData.append("description", this.state.description)
        formData.append("note", this.state.commentaire)
        formData.append("note_avoir", this.state.commentaireAvoir)
        formData.append("allocations", JSON.stringify(table2))

        formDataJson.montant_restant = this.state.montantRestant
        formDataJson.montant_avoir = this.state.montantCompense
        formDataJson.numero_devis = this.state.devis
        formDataJson.statut = this.state.statut
        formDataJson.date_facture = moment(this.state.dateFacture).format("YYYY-MM-DD[T]HH:mm")
        formDataJson.montant_total = this.state.montantTotal
        formDataJson.am = this.state.amValue
        formDataJson.ref_bc = this.state.ReferenceBC
        formDataJson.numero_facture = this.state.numero_facture
        formDataJson.description = this.state.description
        formDataJson.note = this.state.commentaire
        formDataJson.note_avoir = this.state.commentaireAvoir
        formDataJson.allocations = JSON.stringify(table2)

        // Submit the form after waiting for all base64 conversions
        Promise.all(base64Promises).then(() => this.props.add_facture_action(formDataJson))
      } else {
        this.setState({ disabled: false })
      }
    } catch (e) {
      console.error("erreur form :", e)
    }
  }

  resetErrors() {
    let table2 = []
    table2.length = this.state.errorListAllocationsAdded.length
    table2.fill({ errorTicker: "", errorrevenu: "", errorAllocation_offre: "", errorMontant: "", errorDate: "" })
    this.setState({
      errorListAllocationsAdded: table2,
      errorStatut: "",
      errorAmValue: "",
      errorDevis: "",
      errorFacture: "",
      errorDescription: "",
      errorMontantTotal: "",
      errorMontantPaye: "",
      errorMontantRestantPaye: "",
      errorMontantRestant: "",
      errorDateFacture: "",
      errorDateAccuse: "",
      errorEntiteFacturation: "",
      errorReferenceBC: ""
    })
  }

  resetForm() {
    let table = []
    let table4 = []
    table.push({
      tickerlabel: "",
      ticker: "",
      allocationlabel: "",
      revenu: 100,
      allocation_offre: "",
      montant: "",
      date: null
    })
    table4.push({
      tickerlabel: "",
      ticker: "",
      allocationlabel: "",
      revenu: 100,
      allocation_offre: "",
      montant: "",
      date: null
    })
    let table2 = [{ errorTicker: "", errorrevenu: "", errorAllocation_offre: "", errorMontant: "", errorDate: "" }]
    let table3 = [{ type: "ouvert" }]
    this.setState({
      amList: [],
      tickerList: [],
      ListAllocationsAdded: table,
      ListAllocationsAddedSubmit: table4,
      errorListAllocationsAdded: table2,
      tickerStatut: table3,
      statut: "",
      amValue: this.state.user.am ? this.state.user.id : "",
      amDisplay: this.state.user.am ? this.state.user.user.first_name + " " + this.state.user.user.last_name : "",
      devis: "",
      numero_facture: "",
      description: "",
      montantTotal: 0,
      montantRestant: 0,
      montantPaye: 0,
      montantRestantPaye: 0,
      dateFacture: null,
      dateAccuse: null,
      devisFile: null,
      factureFile: null,
      Referencefile: null,
      fichierScanne: null,
      datePrePaiement: null,
      attestationExo: null,
      BonLivraison: null,
      montantCompense: null,
      commentaireAvoir: null,
      commentaire: null,
      entiteFacturation: "",
      errorStatut: "",
      errorAmValue: "",
      errorDevis: "",
      errorFacture: "",
      errorDescription: "",
      errorReferenceBC: "",
      errorMontantTotal: "",
      errorMontantRestant: "",
      errorMontantPaye: "",
      errorMontantRestantPaye: "",
      errorDateFacture: "",
      errorDateAccuse: "",
      errorEntiteFacturation: "",
      errordatePrePaiement: "",
      errorattestationExo: "",
      errorBonLivraison: "",
      errormontantCompense: "",
      errorcommentaireAvoir: "",
      errorcommentaire: "",
    })
  }

  changeTickervalue(id, lable, key) {
    let table = this.state.ListAllocationsAdded
    let table2 = this.state.ListAllocationsAddedSubmit
    table[key].tickerlabel = lable
    table[key].ticker = id
    table2[key].tickerlabel = lable
    table2[key].ticker = id
    this.setState({ ListAllocationsAdded: table, ListAllocationsAddedSubmit: table2 })
  }

  changeAllocationvalue(id, lable, key) {
    let table = this.state.ListAllocationsAdded
    let table2 = this.state.ListAllocationsAddedSubmit
    table[key].allocationlabel = lable
    table[key].allocation_offre = id
    table2[key].allocationlabel = lable
    table2[key].allocation_offre = id
    const result = this.state.revenueList.filter(poucentage => poucentage.allocation_offre === id)
    if (result.length > 0) {
      table[key].revenu = result[0].revenu
      table2[key].revenu = result[0].revenu
    }

    this.setState({ ListAllocationsAdded: table, ListAllocationsAddedSubmit: table2 })
  }

  changeMontantvalue(value, key) {
    let table = this.state.ListAllocationsAdded
    table[key].montant = value
    let table2 = this.state.ListAllocationsAddedSubmit
    table2[key].montant = value
    this.setState({ ListAllocationsAdded: table, ListAllocationsAddedSubmit: table2 }, () => {
      this.setState({ montantRestant: (parseInt((parseFloat(this.state.montantTotal) - this.totalMontantAllocations()) * 100) / 100).toFixed(2) })
    })
  }

  changePourcenntagevalue(value, key) {
    let table = this.state.ListAllocationsAdded
    table[key].revenu = value
    let table2 = this.state.ListAllocationsAddedSubmit
    table2[key].revenu = value
    this.setState({ ListAllocationsAdded: table, ListAllocationsAddedSubmit: table2 })
  }

  changeDatevalue(value, key) {
    let table = this.state.ListAllocationsAdded
    let table2 = this.state.ListAllocationsAddedSubmit
    table[key].date = moment(value).toDate()
    table2[key].date = moment(value).format("M-YYYY")
    this.setState({ ListAllocationsAdded: table, ListAllocationsAddedSubmit: table2 })
  }

  AddLigne() {
    let table = this.state.ListAllocationsAdded
    let table2 = this.state.errorListAllocationsAdded
    let table3 = this.state.ListAllocationsAddedSubmit
    let table4 = this.state.tickerStatut
    let table5 = this.state.open
    table.push({
      tickerlabel: "",
      ticker: "",
      allocationlabel: "",
      revenu: 100,
      allocation_offre: "",
      montant: "",
      date: null
    })
    table2.push({ errorTicker: "", errorrevenu: "", errorAllocation_offre: "", errorMontant: "", errorDate: "" })
    table3.push({
      tickerlabel: "",
      ticker: "",
      allocationlabel: "",
      revenu: 100,
      allocation_offre: "",
      montant: "",
      date: null
    })
    table4.push({ type: "ouvert" })
    table5.push(false)
    this.setState({
      ListAllocationsAdded: table,
      errorListAllocationsAdded: table2,
      ListAllocationsAddedSubmit: table3,
      tickerStatut: table4,
      open: table5
    })
  }

  DeleteLigne(key) {
    let table = this.state.ListAllocationsAdded
    let table2 = this.state.errorListAllocationsAdded
    let table3 = this.state.ListAllocationsAddedSubmit
    let table4 = this.state.tickerStatut
    let table5 = this.state.open

    table = table.filter(function (value, index) {
      return (index !== key)
    })
    table2 = table2.filter(function (value, index) {
      return (index !== key)
    })
    table3 = table3.filter(function (value, index) {
      return (index !== key)
    })
    table4 = table4.filter(function (value, index) {
      return (index !== key)
    })
    table5 = table5.filter(function (value, index) {
      return (index !== key)
    })
    this.setState({
      ListAllocationsAdded: table,
      errorListAllocationsAdded: table2,
      ListAllocationsAddedSubmit: table3,
      tickerStatut: table4,
      open: table5
    })
  }

  statutTicker(ticker, key) {
    const table = this.state.tickerStatut
    table[key] = ticker
    this.setState({ tickerStatut: table })

  }

  totalMontantAllocations() {
    let total = 0
    this.state.ListAllocationsAdded.map((element) => {
      total = parseFloat(total) + parseFloat(element.montant)
    })

    if (!total) return 0

    return total
  }

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        onClose={this.props.handleCloseDialog}
        open={this.props.OpenDialogState}
      >
        <DialogTitle className="text-center">
          Ajouter facture
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormControl
                margin="dense"
                size="small"
                fullWidth
                className="backgroudWhite"
              >
                <InputLabel id="demo-simple-select-label">Statut</InputLabel>
                <Select
                  id="demo-multiple-checkbox"
                  label="Statut"
                  onChange={(event) => {
                    this.setState({ statut: event.target.value }, () => {
                      this.resetErrors()
                    })
                  }}
                  value={this.state.statut}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">---</MenuItem>
                  {this.state.ListStatut.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.nom}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {this.state.errorStatut != "" && (
                <label className="error">{this.state.errorStatut}</label>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl margin="dense" className="backgroudWhite" fullWidth size="small">
                <Autocomplete
                  id="free-solo-demo"
                  size="small"
                  disabled={!this.props.liste_permissions.data.some(permission => permission.type === "superuser")}
                  freeSolo
                  value={this.state.amDisplay}
                  onChange={(event, value) => {
                    if (value) {
                      this.setState({ amDisplay: value, amValue: value.id })
                    } else {
                      this.setState({ amDisplay: "", amValue: "" })
                    }
                  }}
                  options={this.state.amList}
                  getOptionLabel={(item) => {
                    if (item) {
                      return item.user.first_name + " " + item.user.last_name
                    } else {
                      return ""
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="AM" />}
                />
              </FormControl>
              {this.state.errorAmValue != "" && (
                <label className="error">{this.state.errorAmValue}</label>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
                style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}
              >
                <TextField
                  id="outlined-basic"
                  label="No Devis"
                  value={this.state.devis}
                  onChange={(event) => {
                    this.setState({ devis: event.target.value })
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                <button className="fileupload"><i className="fas fa-paperclip"></i></button>
                <input
                  className="inputfileupload"
                  type="file"
                  name="devisFile"
                  onChange={(event) => {
                    this.setState({ devisFile: event.target.files[0] })
                  }}
                />
              </FormControl>
              {this.state.devisFile !== null ? (
                this.state.devisFile.size ?
                  <label className="error">{this.state.devisFile.name}</label>
                  :
                  this.state.devisFile.lien ? (
                    <a href={this.state.devisFile.lien} target="blank"><label
                      className="error">{this.state.devisFile.name}</label></a>
                  ) : ""
              ) : ""
              }
              {this.state.errorDevis != "" && (
                <label className="error">{this.state.errorDevis}</label>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
                style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}
              >
                <TextField
                  id="outlined-basic"
                  label="No facture"
                  value={this.state.numero_facture}
                  onChange={(event) => {
                    this.setState({ numero_facture: event.target.value })
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                <button className="fileupload"><i className="fas fa-paperclip"></i></button>
                <input
                  className="inputfileupload"
                  type="file"
                  name="factureFile"
                  onChange={(event) => {
                    this.setState({ factureFile: event.target.files[0] })
                  }}
                />
              </FormControl>
              {this.state.factureFile !== null ? (
                this.state.factureFile.size ?
                  <label className="error">{this.state.factureFile.name}</label>
                  :
                  this.state.factureFile.lien ? (
                    <a href={this.state.factureFile.lien} target="blank"><label
                      className="error">{this.state.factureFile.name}</label></a>
                  ) : ""
              ) : ""
              }
              {this.state.errornumero_facture != "" && (
                <label className="error">{this.state.errornumero_facture}</label>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
                style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}
              >
                <TextField
                  id="outlined-basic"
                  label="Réference BC"
                  value={this.state.ReferenceBC}
                  onChange={(event) => {
                    this.setState({ ReferenceBC: event.target.value })
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                <button className="fileupload"><i className="fas fa-paperclip"></i></button>
                <input
                  className="inputfileupload"
                  type="file"
                  name="Referencefile"
                  onChange={(event) => {
                    this.setState({ Referencefile: event.target.files[0] })
                  }}
                />
              </FormControl>
              {this.state.Referencefile !== null ? (
                this.state.Referencefile.size ?
                  <label className="error">{this.state.Referencefile.name}</label>
                  :
                  this.state.Referencefile.lien ? (
                    <a href={this.state.Referencefile.lien} target="blank"><label
                      className="error">{this.state.Referencefile.name}</label></a>
                  ) : ""
              ) : ""
              }
              {this.state.errorReferenceBC != "" && (
                <label className="error">{this.state.errorReferenceBC}</label>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Description"
                  value={this.state.description}
                  onChange={(event) => {
                    this.setState({ description: event.target.value })
                  }}
                  variant="outlined"
                  size="small"
                />
              </FormControl>
              {this.state.errorDescription != "" && (
                <label className="error">{this.state.errorDescription}</label>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Montant Total HT"
                  value={this.state.montantTotal}
                  onChange={(event) => {
                    this.setState({
                      montantTotal: event.target.value !== "" ? parseFloat(event.target.value) : "",
                      montantRestant: (parseInt((parseFloat(event.target.value) - this.totalMontantAllocations()) * 100) / 100).toFixed(2),
                      montantRestantPaye: parseFloat(event.target.value - this.state.montantPaye).toFixed(2)
                    })
                  }}
                  variant="outlined"
                  size="small"
                  type="number"
                />
              </FormControl>
              {this.state.errorMontantTotal != "" && (
                <label className="error">{this.state.errorMontantTotal}</label>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                margin="dense"
                className="backgroudGrey"
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Montant Restant à allouer HT"
                  value={this.state.montantRestant}
                  onChange={(event) => {
                    this.setState({ montantRestant: event.target.value })
                  }}
                  disabled
                  variant="outlined"
                  size="small"
                  type="number"
                />
              </FormControl>
              {this.state.errorMontantRestant != "" && (
                <label className="error">{this.state.errorMontantRestant}</label>
              )}
              {parseFloat(this.state.montantRestant) < 0 && (
                <label className="error">Vous avez dépassé le montant total</label>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date Facture"
                  inputFormat="dd/MM/yyyy"
                  value={this.state.dateFacture}
                  selected={this.state.dateFacture}
                  onChange={(date) => {
                    this.setState({ dateFacture: date !== null ? moment(date).toDate() : null })
                  }}
                  renderInput={(params) => <TextField
                    margin="dense"
                    className="backgroudWhite"
                    fullWidth
                    size="small" {...params}
                  />}
                />
              </LocalizationProvider>
              {this.state.errorDateFacture != "" && (
                <label className="error">{this.state.errorDateFacture}</label>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                margin="dense"
                size="small"
                fullWidth
                className="backgroudWhite"
              >
                <InputLabel id="demo-simple-select-label">Entité de facturation</InputLabel>
                <Select
                  id="demo-multiple-checkbox"
                  label="Entité de facturation"
                  onChange={(event) => {
                    this.setState({ entiteFacturation: event.target.value })
                  }}
                  value={this.state.entiteFacturation}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">---</MenuItem>
                  {this.state.ListEntite.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.nom_entreprise}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {this.state.errorStatut != "" && (
                <label className="error">{this.state.errorStatut}</label>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                margin="dense"
                className="backgroudGrey"
                fullWidth
                size="small"
                style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}
              >
                <TextField
                  id="outlined-basic"
                  label="Attestation d'exonération de TVA"
                  value={this.state.attestationExo ? this.state.attestationExo.name : ""}
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                <button className="fileupload"><i className="fas fa-paperclip"></i></button>
                <input
                  className="inputfileupload"
                  type="file"
                  name="attestationExo"
                  onChange={(event) => {
                    this.setState({ attestationExo: event.target.files[0] })
                  }}
                />
              </FormControl>
              {this.state.attestationExo !== null ? (
                this.state.attestationExo.size ?
                  <label className="error">{this.state.attestationExo.name}</label>
                  :
                  this.state.attestationExo.lien ? (
                    <a href={this.state.attestationExo.lien} target="blank"><label
                      className="error">{this.state.attestationExo.name}</label></a>
                  ) : ""
              ) : ""
              }
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                margin="dense"
                className="backgroudGrey"
                fullWidth
                size="small"
                style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}
              >
                <TextField
                  id="outlined-basic"
                  label="Bon de livraison"
                  value={this.state.BonLivraison ? this.state.BonLivraison.name : ""}
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                <button className="fileupload"><i className="fas fa-paperclip"></i></button>
                <input
                  className="inputfileupload"
                  type="file"
                  name="BonLivraison"
                  onChange={(event) => {
                    this.setState({ BonLivraison: event.target.files[0] })
                  }}
                />
              </FormControl>
              {this.state.BonLivraison !== null ? (
                this.state.BonLivraison.size ?
                  <label className="error">{this.state.BonLivraison.name}</label>
                  :
                  this.state.BonLivraison.lien ? (
                    <a href={this.state.BonLivraison.lien} target="blank"><label
                      className="error">{this.state.BonLivraison.name}</label></a>
                  ) : ""
              ) : ""
              }
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date prévisionnelle de paiment"
                  inputFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    this.setState({ datePrePaiement: date !== null ? moment(date).toDate() : null })
                  }}
                  value={this.state.datePrePaiement}
                  selected={this.state.datePrePaiement}
                  renderInput={(params) => <TextField
                    margin="dense"
                    className="backgroudWhite"
                    fullWidth
                    size="small" {...params}
                  />}
                />
              </LocalizationProvider>
              {this.state.errordatePrePaiement != "" && (
                <label className="error">{this.state.errordatePrePaiement}</label>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Commentaire"
                  value={this.state.commentaire}
                  onChange={(event) => {
                    this.setState({ commentaire: event.target.value })
                  }}
                  variant="outlined"
                  size="small"
                />
              </FormControl>
              {this.state.errorcommentaire != "" && (
                <label className="error">{this.state.errorcommentaire}</label>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: "#e5e5f2",
                    padding: "0px 10px",
                    margin: "0px",
                  }}
                >
                  <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>Champs comptablité</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl
                        margin="dense"
                        className="backgroudWhite"
                        fullWidth
                        size="small"
                        style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Montant payé par le client"
                          value={this.state.montantPaye}
                          onChange={(event) => {
                            this.setState({
                              montantPaye: event.target.value !== "" ? parseFloat(event.target.value) : "",
                              montantRestantPaye: parseFloat(this.state.montantTotal - event.target.value).toFixed(2)
                            })
                          }}
                          variant="outlined"
                          size="small"
                          type="number"
                          fullWidth
                        />
                        <button className="fileupload" onClick={() => this.setState({
                          montantPaye: this.state.montantTotal,
                          montantRestantPaye: 0
                        })}><i className="fa fa-redo"></i></button>
                      </FormControl>
                      {this.state.errorMontantTotal != "" && (
                        <label className="error">{this.state.errorMontantTotal}</label>
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl
                        margin="dense"
                        className="backgroudGrey"
                        fullWidth
                        size="small"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Montant Restant à payé"
                          value={this.state.montantRestantPaye}
                          onChange={(event) => {
                            this.setState({ montantRestantPaye: event.target.value })
                          }}
                          disabled
                          variant="outlined"
                          size="small"
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date d'accusé reception par le client"
                          inputFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            this.setState({ dateAccuse: date !== null ? moment(date).toDate() : null })
                          }}
                          value={this.state.dateAccuse}
                          selected={this.state.dateAccuse}
                          renderInput={(params) => <TextField
                            margin="dense"
                            className="backgroudWhite"
                            fullWidth
                            size="small" {...params}
                          />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl
                        margin="dense"
                        className="backgroudGrey"
                        fullWidth
                        size="small"
                        style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="fichier scanné d'accusé reception"
                          value={this.state.fichierScanne ? this.state.fichierScanne.name : ""}
                          disabled
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                        <button className="fileupload"><i className="fas fa-paperclip"></i></button>
                        <input
                          className="inputfileupload"
                          type="file"
                          name="fichierScanne"
                          onChange={(event) => {
                            this.setState({ fichierScanne: event.target.files[0] })
                          }}
                        />
                      </FormControl>
                      {this.state.fichierScanne !== null ? (
                        this.state.fichierScanne.size ?
                          <label className="error">{this.state.fichierScanne.name}</label>
                          :
                          this.state.fichierScanne.lien ? (
                            <a href={this.state.fichierScanne.lien} target="blank"><label
                              className="error">{this.state.fichierScanne.name}</label></a>
                          ) : ""
                      ) : ""
                      }
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl
                        margin="dense"
                        className="backgroudWhite"
                        fullWidth
                        size="small"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Montant compensé par avoir HT"
                          value={this.state.montantCompense}
                          onChange={(event) => {
                            this.setState({ montantCompense: event.target.value !== "" ? parseFloat(event.target.value) : "" })
                          }}
                          variant="outlined"
                          size="small"
                          type="number"
                          fullWidth
                        />
                      </FormControl>
                      {this.state.errormontantCompense != "" && (
                        <label className="error">{this.state.errormontantCompense}</label>
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl
                        margin="dense"
                        className="backgroudWhite"
                        fullWidth
                        size="small"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Commentaire avoir"
                          value={this.state.commentaireAvoir}
                          onChange={(event) => {
                            this.setState({ commentaireAvoir: event.target.value })
                          }}
                          variant="outlined"
                          size="small"
                        />
                      </FormControl>
                      {this.state.errorcommentaireAvoir != "" && (
                        <label className="error">{this.state.errorcommentaireAvoir}</label>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: "#e5e5f2",
                    padding: "0px 10px",
                    margin: "0px",
                  }}
                >
                  <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>Allocations</h6>
                </AccordionSummary>
                <AccordionDetails>
                  {this.state.ListAllocationsAdded.map((test, key) => {
                    return (
                      <Grid key={key} className="Border-content" container spacing={1}>
                        <button className="button-delete" onClick={() => this.DeleteLigne(key)}>&times;</button>
                        <Grid item xs={12} md={4}>
                          <FormControl margin="dense" className="backgroudWhite" fullWidth size="small">
                            <Autocomplete
                              id="asynchronous-demo"
                              size="small"
                              open={this.state.open[key]}
                              onOpen={() => {
                                this.changeOpen(true, key)
                              }}
                              onClose={() => {
                                this.changeOpen(false, key)
                              }}
                              freeSolo
                              value={this.state.ListAllocationsAdded[key].tickerlabel && this.state.ListAllocationsAdded[key].tickerlabel}
                              options={this.state.tickerList}
                              getOptionLabel={(item) => {
                                if (item) {
                                  return item.label
                                } else {
                                  return ""
                                }
                              }}
                              onChange={(event, value) => {
                                if (value) {
                                  this.changeTickervalue(value.id, value, key)
                                } else {
                                  this.changeTickervalue("", "", key)
                                }
                              }}
                              onInputChange={(event, newInputValue) => {
                                this.setState({
                                  tickerList: [],
                                })
                                if (newInputValue.length > 2) this.props.liste_tickers_search_value_action(newInputValue)
                              }}
                              loading={this.state.open[key] && this.state.tickerList.length === 0}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Ticker"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {this.state.open[key] && this.state.tickerList.length === 0 ?
                                          <CircularProgress style={{ marginRight: 25 }} color="inherit"
                                            size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                          {this.state.errorListAllocationsAdded[key].errorTicker != "" && (
                            <label className="error">{this.state.errorListAllocationsAdded[key].errorTicker}</label>
                          )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl margin="dense" className="backgroudWhite" fullWidth size="small">
                            <Autocomplete
                              id="free-solo-demo"
                              size="small"
                              freeSolo
                              value={this.state.ListAllocationsAdded[key].allocationlabel}
                              onChange={(event, value) => {
                                if (value) {
                                  this.changeAllocationvalue(value.id, value, key)
                                } else {
                                  this.changeAllocationvalue("", "", key)
                                }
                              }}
                              options={this.state.allocationList}
                              getOptionLabel={(item) => {
                                if (item) {
                                  return item.nom
                                } else {
                                  return ""
                                }
                              }}
                              renderInput={(params) => <TextField {...params} label="Allocation offre" />}
                            />
                          </FormControl>
                          {this.state.errorAmValue != "" && (
                            <label className="error">{this.state.errorAmValue}</label>
                          )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl
                            margin="dense"
                            className="backgroudWhite"
                            fullWidth
                            size="small"
                          >
                            <TextField
                              id="outlined-basic"
                              label="Montant Total HT"
                              type="number"
                              value={this.state.ListAllocationsAdded[key].montant}
                              onChange={(event) => this.changeMontantvalue(event.target.value, key)}
                              variant="outlined"
                              size="small"
                            />
                          </FormControl>
                          {this.state.errorListAllocationsAdded[key].errorMontant != "" && (
                            <label className="error">{this.state.errorListAllocationsAdded[key].errorMontant}</label>
                          )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl
                            margin="dense"
                            className="backgroudWhite"
                            fullWidth
                            size="small"
                          >
                            <TextField
                              id="outlined-basic"
                              label="% revenu"
                              type="number"
                              value={this.state.ListAllocationsAdded[key].revenu}
                              onChange={(event) => this.changePourcenntagevalue(event.target.value, key)}
                              variant="outlined"
                              size="small"
                            />
                          </FormControl>
                          {this.state.errorListAllocationsAdded[key].errorrevenu != "" && (
                            <label className="error">{this.state.errorListAllocationsAdded[key].errorrevenu}</label>
                          )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl
                            margin="dense"
                            className="backgroudWhite"
                            fullWidth
                            size="small"
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                views={["year", "month"]}
                                label="Date"
                                className="backgroudWhite"
                                fullWidth
                                size="small"
                                value={this.state.ListAllocationsAdded[key].date}
                                disabled={this.state.ListAllocationsAdded[key].tickerlabel.type !== "mensuel"}
                                onChange={(value) => {
                                  if (value) {
                                    this.changeDatevalue(value, key)
                                  } else {
                                    this.changeDatevalue("", key)
                                  }
                                }}
                                renderInput={(params) => <TextField {...params} size="small" />}
                              />
                            </LocalizationProvider>
                          </FormControl>
                          {this.state.errorListAllocationsAdded[key].errorrevenu != "" && (
                            <label className="error">{this.state.errorListAllocationsAdded[key].errorrevenu}</label>
                          )}
                        </Grid>
                      </Grid>
                    )
                  }
                  , this)}
                    <button className="button-add" onClick={() => this.AddLigne()}><span>+ Ajouter allocation</span>
                    </button>

                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Formbutton
                classname="btn btn-primary mt-3"
                textBtn="Ajouter"
                colorBtn="#252454"
                disabled={this.state.disabled}
                disabledBloc={parseFloat(this.state.montantRestant) != 0.00 && this.state.montantRestant != ""}
                onClick={() => {
                  this.disabledBtn()
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  entite: state.facture.entite,
  statut_facture: state.facture.statut_facture,
  allocation_offre: state.facture.allocation_offre,
  revenue: state.facture.revenue,
  liste_am: state.ticker.liste_am,
  liste_tickers_search: state.ticker.liste_tickers_search,
  liste_tickers_search_value: state.ticker.liste_tickers_search_value,
  user_info: state.user.user_info,
  add_facture: state.facture.add_facture,
  liste_permissions: state.user.liste_permissions,
})

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      liste_allocation_offre_action: liste_allocation_offre_action,
      entite_action: entite_action,
      statut_facture_action: statut_facture_action,
      revenue_liste_action: revenue_liste_action,
      liste_am_action: liste_am_action,
      liste_tickers_search_action: liste_tickers_search_action,
      add_facture_action: add_facture_action,
      liste_tickers_search_value_action: liste_tickers_search_value_action,
    },
    dispatch
  )
)

export default connect(mapStateToProps, mapDispatchToProps)(AjouterFactureDialog)
