import { put } from "redux-saga/effects"

function* callApi(url, requestFunction, requestBody, callbackAction, returnId) {
  try {
    let response
    if (requestBody) {
      response = yield requestFunction(url, requestBody)
    } else {
      response = yield requestFunction(url)
    }

    if (response) {
      const formattedData = response
      if (callbackAction) {
        if (returnId) {
          yield put(callbackAction({ data: formattedData, body: requestBody }))
        } else {
          yield put(callbackAction(formattedData))
        }
      }
    }
  } catch (e) {
    if (returnId) {
      yield put(callbackAction({ data: e.response, body: requestBody }))
    } else {
      yield put(callbackAction(e.response))
    }
  }
}

export { callApi }
