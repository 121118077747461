// add_maladie
export function add_maladie_action(payload) {
  return {
    type: "ADD_MALADIE",
    payload,
  }
}

export function add_maladie_action_done(request) {
  return {
    type: "ADD_MALADIE_DONE",
    payload: request,
  }
}

// edit_maladie
export function edit_maladie_action(payload) {
  return {
    type: "EDIT_MALADIE",
    payload,
  }
}

export function edit_maladie_action_done(request) {
  return {
    type: "EDIT_MALADIE_DONE",
    payload: request,
  }
}

// list_maladie
export function get_maladie_action(payload) {
  return {
    type: "LIST_MALADIE",
    payload,
  }
}

export function get_maladie_action_done(request) {
  return {
    type: "LIST_MALADIE_DONE",
    payload: request,
  }
}

// list_maladie_uploade
export function get_maladie_uploade_action(payload) {
  return {
    type: "LIST_MALADIE_UPLOADE",
    payload,
  }
}

export function get_maladie_uploade_action_done(request) {
  return {
    type: "LIST_MALADIE_UPLOADE_DONE",
    payload: request,
  }
}

// list_maladie_valide
export function get_maladie_valide_action(payload) {
  return {
    type: "LIST_MALADIE_VALIDE",
    payload,
  }
}

export function get_maladie_valide_action_done(request) {
  return {
    type: "LIST_MALADIE_VALIDE_DONE",
    payload: request,
  }
}

// list_all_maladie
export function get_all_maladie_action(payload) {
  return {
    type: "LIST_ALL_MALADIE",
    payload,
  }
}

export function get_all_maladie_action_done(request) {
  return {
    type: "LIST_ALL_MALADIE_DONE",
    payload: request,
  }
}

// list_all_maladie_uploade
export function get_all_maladie_uploade_action(payload) {
  return {
    type: "LIST_ALL_MALADIE_UPLOADE",
    payload,
  }
}

export function get_all_maladie_uploade_action_done(request) {
  return {
    type: "LIST_ALL_MALADIE_UPLOADE_DONE",
    payload: request,
  }
}

// list_all_maladie_valide
export function get_all_maladie_valide_action(payload) {
  return {
    type: "LIST_ALL_MALADIE_VALIDE",
    payload,
  }
}

export function get_all_maladie_valide_action_done(request) {
  return {
    type: "LIST_ALL_MALADIE_VALIDE_DONE",
    payload: request,
  }
}
