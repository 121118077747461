import { QueryClient } from "@tanstack/query-core"

const queryConfig = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      staleTime: Number(process.env.REACT_APP_QUERY_STALE_TIME) * 1000,
      cacheTime: Number(process.env.REACT_APP_QUERY_CACHE_TIME) * 1000,
    },
    mutations: {
      retry: false,
    },
  }
})

export default queryConfig
