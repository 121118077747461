import { call, takeEvery } from "redux-saga/effects"
import {
  day_before_action_done,
  Liste_suivi_timesheet_action_done,
  next_day_action_done,
  publish_timesheet_action_done,
  ticker_admin_action_done,
  ticker_saved_action_done,
} from "../actions/timesheet"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, post } from "../services/request"


// Next_day
function* next_day_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETNEXTDAY}`,
    get,
    null,
    next_day_action_done
  )
}

export function* watch_next_day_saga() {
  yield takeEvery("NEXT_DAY", next_day_saga)
}


// day_before
function* day_before_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETDAYBEFORE}`,
    get,
    null,
    day_before_action_done
  )
}

export function* watch_day_before_saga() {
  yield takeEvery("DAY_BEFORE", day_before_saga)
}


// ticker_admin
function* ticker_admin_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETSPECIAL}?date=${action.payload}`,
    get,
    null,
    ticker_admin_action_done
  )
}

export function* watch_ticker_admin_saga() {
  yield takeEvery("TICKER_ADMIN", ticker_admin_saga)
}


// ticker_saved
function* ticker_saved_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETSAVEDTIME}?date=${action.payload.date}${action.payload.tri ? "&tri=" + action.payload.tri : ""}`,
    get,
    null,
    ticker_saved_action_done
  )
}

export function* watch_ticker_saved_saga() {
  yield takeEvery("TICKER_SAVED", ticker_saved_saga)
}


// puclish_timesheet
function* publish_timesheet_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETTIMESHEETS}`,
    post,
    action.payload,
    publish_timesheet_action_done
  )
}

export function* watch_publish_timesheet_saga() {
  yield takeEvery("PUBLISH_TIMESHEET", publish_timesheet_saga)
}


// puclish_timesheet
function* suivi_timesheet_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_SUIVITIMESHEETS}`,
    get,
    null,
    Liste_suivi_timesheet_action_done
  )
}

export function* watch_suivi_timesheet_saga() {
  yield takeEvery("SUIVI_TIMESHEET", suivi_timesheet_saga)
}
