// liste_team_disponible
export function liste_team_disponible_action(payload) {
  return {
    type: "LISTE_TEAM_DISPONIBLE",
    payload,
  }
}

export function liste_team_disponible_action_done(request) {
  return {
    type: "LISTE_TEAM_DISPONIBLE_DONE",
    payload: request,
  }
}

// user_disponible
export function user_disponible_action(payload) {
  return {
    type: "USER_DISPONIBLE",
    payload,
  }
}

export function user_disponible_action_done(request) {
  return {
    type: "USER_DISPONIBLE_DONE",
    payload: request,
  }
}

// create_non_staffe
export function create_non_staffe_action(payload) {
  return {
    type: "CREATE_NON_STAFFE",
    payload,
  }
}

export function create_non_staffe_action_done(request) {
  return {
    type: "CREATE_NON_STAFFE_DONE",
    payload: request,
  }
}

// update_non_staffe
export function update_non_staffe_action(payload) {
  return {
    type: "UPDATE_NON_STAFFE",
    payload,
  }
}

export function update_non_staffe_action_done(request) {
  return {
    type: "UPDATE_NON_STAFFE_DONE",
    payload: request,
  }
}
