import { Autocomplete, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, } from "@mui/material"
import React, { Component } from "react"
import { connect } from "react-redux"
import Formbutton from "./ButtonForm"
import { bindActionCreators } from "redux"
import swal from "sweetalert2/dist/sweetalert2.all.min.js"
import withReactContent from "sweetalert2-react-content"
import urls from "../../services/urls"
import moment from "moment"
import { add_ticker_action, liste_bu_action, liste_client_action, liste_lettre_ticker_action, liste_marque_action, liste_type_categories_action } from "../../actions/ticker"
import "./commun.css"

moment.locale("fr")

const MySwal = withReactContent(swal)
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

class AjouterTickerDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: "",
      marque: "",
      marqueId: "",
      marqueList: [],
      marqueListSearch: [],
      client: "",
      clientId: "",
      ListClient: [],
      bu: "",
      ListBu: [],
      code: "",
      ListCode: [],
      CategorieFilter: "",
      categories: [],
      types: [],
      type_categories: [],
      TypeCategorieFilter: "",
      color: "",
      nom: "",
      libelle: "",
      lettre: "",
      commentaire: "oui",
      disabled: false,
      marqueError: "",
      clientError: "",
      TypeCategorieError: "",
      ColorError: "",
      CodeError: "",
      LibelleError: "",
      NomError: "",
      showButton: false,
    }

  }

  componentDidMount() {
    const { liste_permissions } = this.props

    if (liste_permissions && liste_permissions.status === 200) {
      if (liste_permissions.data && liste_permissions.data.some(item => "valider_demande_ticker" === item.type)) {
        this.setState({
          showButton: true,
        })
        this.props.liste_lettre_ticker_action()
        if (this.props.marqueApi) this.props.liste_marque_action()
        if (this.props.categorieApi) this.props.liste_type_categories_action()
        if (this.props.buApi) this.props.liste_bu_action()
        if (this.props.clientApi) this.props.liste_client_action()
      } else {
        this.setState({
          showButton: false,
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      liste_type_categories,
      liste_marque,
      liste_lettre,
      liste_bu,
      liste_client,
      add_ticker,
      liste_permissions
    } = this.props

    if (prevProps.liste_permissions !== liste_permissions) {
      if (liste_permissions && liste_permissions.status === 200) {
        if (liste_permissions.data && liste_permissions.data.some(item => "valider_demande_ticker" === item.type)) {
          this.setState({
            showButton: true,
          })
          this.props.liste_lettre_ticker_action()
          if (this.props.marqueApi) this.props.liste_marque_action()
          if (this.props.categorieApi) this.props.liste_type_categories_action()
          if (this.props.buApi) this.props.liste_bu_action()
          if (this.props.clientApi) this.props.liste_client_action()
        } else {
          this.setState({
            showButton: false,
          })
        }
      }
    }

    if (prevProps.liste_type_categories !== liste_type_categories) {
      if (liste_type_categories && liste_type_categories.status === 200) {
        this.setState({
          type_categories: liste_type_categories.data,
          types: liste_type_categories.data,
        })
        let categoriesArray = []
        liste_type_categories.data.map((item) => {
          if (
            !categoriesArray.some(
              (itemSome) => item.categorie.id === itemSome.id
            )
          ) {
            categoriesArray.push(item.categorie)
          }
        })
        this.setState({
          categories: categoriesArray,
        })
      }
    }

    if (prevProps.liste_marque !== liste_marque) {
      if (liste_marque && liste_marque.status === 200) {
        this.setState({
          marqueList: liste_marque.data,
          marqueListSearch: liste_marque.data,
        })
      }
    }

    if (prevProps.liste_lettre !== liste_lettre) {
      if (liste_lettre && liste_lettre.status === 200) {
        this.setState({
          ListCode: liste_lettre.data,
        })
      }
    }

    if (prevProps.liste_bu !== liste_bu) {
      if (liste_bu && liste_bu.status === 200) {
        this.setState({
          ListBu: liste_bu.data
        })
      }
    }

    if (prevProps.liste_client !== liste_client) {
      if (liste_client && liste_client.status === 200) {
        this.setState({
          ListClient: liste_client.data
        })
      }
    }

    if (prevProps.add_ticker !== add_ticker) {
      if (add_ticker && add_ticker.status === 201) {
        let date = new Date()
        date = moment(date).format("yyyy-MM-DD")
        this.props.handleCloseDialog()
        MySwal.fire({
          title: <strong>Ticker ajouter avec succès</strong>,
          icon: "success"
        }).then(
          () => {
            window.location.href = `${urls.detailTicker}/${add_ticker.data.id}/${date}`
          }
        )
      }
    }
  }

  disabledBtn() {
    this.setState({ disabled: true })
    this.validateForm()
  }

  validateForm() {
    try {
      let valideForm = true
      if (this.state.bu === "") {
        this.setState({ buError: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ buError: "" })
      }
      if (this.state.clientId === "") {
        this.setState({ clientError: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ clientError: "" })
      }
      if (this.state.marqueId === "") {
        this.setState({ marqueError: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ marqueError: "" })
      }
      if (this.state.libelle === "") {
        this.setState({ libelleError: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ libelleError: "" })
      }
      if (this.state.code === "") {
        this.setState({ codeError: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ codeError: "" })
      }
      if (this.state.TypeCategorieFilter === "") {
        this.setState({ TypeCategorieError: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ TypeCategorieError: "" })
      }
      if (this.state.nom === "") {
        this.setState({ NomError: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ NomError: "" })
      }
      if (this.state.color === "") {
        this.setState({ ColorError: "Ce champ est obligatoire" })
        valideForm = false
      } else {
        this.setState({ ColorError: "" })
      }
      if (valideForm) {
        let data = {
          marque: parseInt(this.state.marqueId),
          code: this.state.code,
          lettre: parseInt(this.state.lettre),
          bu: parseInt(this.state.bu),
          typecategorie: this.state.TypeCategorieFilter,
          label: this.state.libelle,
          nom_projet: this.state.nom,
          couleur: this.state.color,
          description_obligatoire: this.state.commentaire === "oui",
        }
        this.props.add_ticker_action(data)
      } else {
        this.setState({ disabled: false })
      }
    } catch (e) {
      console.error("erreur form :", e)
    }
  }

  changeMarqueFilter(id, value) {
    this.setState({ marqueId: id, marque: value })
  }

  changeClientFilter(id, value) {
    this.setState({ clientId: id, client: value })
  }

  changeBuFilter(value) {
    this.setState({ bu: value })
  }

  changeCodeFilter(value) {
    this.setState({ code: value })
  }

  changeColor(value) {
    this.setState({ color: value })
  }

  changeNom(value) {
    this.setState({ nom: value })
  }

  changeLibelle(value) {
    this.setState({ libelle: value })
  }

  changeCommentaire(value) {
    this.setState({ commentaire: value })
  }

  changeCategorieFilter(id) {
    this.setState({ CategorieFilter: id })
  }

  changeTypeCategorieFilter(id) {
    this.setState({ TypeCategorieFilter: id })
  }

  changeLettreFilter(id) {
    let lastId = this.state.ListCode.filter(item => item.id == id)
    this.setState({ lettre: id, code: lastId[0].get_last_code + 1 })
  }

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={this.props.handleCloseDialog}
        open={this.props.OpenDialogState}
      >
        <DialogTitle className="text-center">
                    Créer un ticker
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4}>
                            Client :
            </Grid>
            <Grid item xs={8} md={8}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <Autocomplete
                  id="free-solo-demo"
                  size="small"
                  freeSolo
                  value={this.state.client}
                  onChange={(event, value) => {
                    if (value) {
                      let liste = this.state.marqueListSearch.filter(element => {
                        return element.client.id === value.id
                      })
                      this.setState({
                        clientId: value.id,
                        client: value,
                        marqueList: liste
                      })
                      if (liste.length === 1) {
                        this.setState({ marque: liste[0], marqueId: liste[0].id })
                      } else {
                        this.setState({ marque: "", marqueId: "" })
                      }
                    } else {
                      this.setState({
                        clientId: "",
                        client: "",
                        marque: "",
                        marqueId: "",
                        marqueList: this.state.marqueListSearch
                      })
                    }

                  }
                  }
                  options={this.state.ListClient}
                  getOptionLabel={(item) => {
                    if (item) {
                      return item.nom_entreprise
                    } else {
                      return ""
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Client" />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <MenuItem
                        {...props}
                        key={`clients${option.id}`}
                        value={option.id}
                      >
                        {option.nom_entreprise}
                      </MenuItem>
                    )
                  }}
                />
              </FormControl>
              {this.state.clientError && <label className="error">{this.state.clientError}</label>}
            </Grid>
            <Grid item xs={4} md={4}>
                            Marque :
            </Grid>
            <Grid item xs={8} md={8}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <Autocomplete
                  id="free-solo-demo"
                  size="small"
                  freeSolo
                  value={this.state.marque}
                  onChange={(event, value) => {
                    if (value) {
                      this.changeMarqueFilter(value.id, value)
                    } else {
                      this.changeMarqueFilter("", "")
                    }
                  }}
                  options={this.state.marqueList}
                  getOptionLabel={(item) => {
                    if (item) {
                      return item.nom
                    } else {
                      return ""
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Marque" />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <MenuItem {...props} key={`Marque_${option.id}`}>
                        {option.nom}
                      </MenuItem>
                    )
                  }}
                />
              </FormControl>
              {this.state.marqueError && <label className="error">{this.state.marqueError}</label>}
            </Grid>
            <Grid item xs={4} md={4}>
                            Bu :
            </Grid>
            <Grid item xs={8} md={8}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <InputLabel id="Bu">Bu</InputLabel>
                <Select
                  labelId="Bu"
                  id="demo-simple-select"
                  label="Bu"
                  value={this.state.bu}
                  onChange={(e) => this.changeBuFilter(e.target.value)}
                  MenuProps={MenuProps}
                  defaultValue={""}
                >
                  <MenuItem value={""}>---</MenuItem>
                  {this.state.ListBu &&
                                        this.state.ListBu.map((type, key) => {
                                          return (
                                            <MenuItem key={`Bu_${key}`} value={type.id}>
                                              {type.nom}
                                            </MenuItem>
                                          )
                                        })}
                </Select>
              </FormControl>
              {this.state.buError && <label className="error">{this.state.buError}</label>}
            </Grid>
            <Grid item xs={4} md={4}>
                            Code :
            </Grid>
            <Grid item xs={2} md={2}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <InputLabel id="Code">Code</InputLabel>
                <Select
                  labelId="Code"
                  id="demo-simple-select"
                  label="Code"
                  value={this.state.lettre}
                  onChange={(e) => this.changeLettreFilter(e.target.value)}
                  MenuProps={MenuProps}
                  defaultValue={""}
                >
                  <MenuItem value={""}>---</MenuItem>
                  {this.state.ListCode &&
                                        this.state.ListCode.map((type, key) => {
                                          return (
                                            <MenuItem
                                              key={`ListeCode${key}`}
                                              value={type.id}
                                            >
                                              {type.lettre}
                                            </MenuItem>
                                          )
                                        })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Code"
                  value={this.state.code}
                  onChange={(e) => this.changeCodeFilter(e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </FormControl>
              {this.state.CodeError && <label className="error">{this.state.CodeError}</label>}
            </Grid>
            <Grid item xs={4} md={4}>
                            Libellé :
            </Grid>
            <Grid item xs={8} md={8}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Libellé"
                  value={this.state.libelle}
                  onChange={(e) => this.changeLibelle(e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </FormControl>
              {this.state.libelleError && <label className="error">{this.state.libelleError}</label>}
            </Grid>
            <Grid item xs={4} md={4}>
                            Nom du projet :
            </Grid>
            <Grid item xs={8} md={8}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Nom du projet"
                  value={this.state.nom}
                  onChange={(e) => this.changeNom(e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </FormControl>
              {this.state.NomError && <label className="error">{this.state.NomError}</label>}
            </Grid>
            <Grid item xs={4} md={4}>
                            Catégorie :
            </Grid>
            <Grid item xs={8} md={8}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <InputLabel id="Categorie">Catégorie</InputLabel>
                <Select
                  labelId="Categorie"
                  id="demo-simple-select"
                  label="Categorie"
                  MenuProps={MenuProps}
                  defaultValue={""}
                  value={this.state.CategorieFilter}
                  onChange={(e) => {
                    this.changeCategorieFilter(e.target.value)
                    let liste = this.state.types.filter(
                      (item) => item.categorie.id === e.target.value
                    )
                    this.setState(
                      {
                        type_categories: e.target.value
                          ? liste
                          : this.state.types,
                      },
                      () => {
                        this.changeTypeCategorieFilter("")
                        if (liste.length === 1) {
                          this.changeTypeCategorieFilter(liste[0].id)
                        }
                      }
                    )
                  }}
                >
                  <MenuItem value={""}>---</MenuItem>
                  {this.state.categories &&
                                        this.state.categories.map((categ, key) => {
                                          return (
                                            <MenuItem key={`categorieTop_${key}`} value={categ.id}>
                                              {categ.categorie}
                                            </MenuItem>
                                          )
                                        }, this)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
                            Type catégorie :
            </Grid>
            <Grid item xs={8} md={8}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
              >
                <InputLabel id="TypeCategorie">Type catégorie</InputLabel>
                <Select
                  labelId="TypeCategorie"
                  id="demo-simple-select"
                  label="TypeCategorie"
                  value={this.state.TypeCategorieFilter}
                  onChange={(e) =>
                    this.changeTypeCategorieFilter(e.target.value)
                  }
                  MenuProps={MenuProps}
                  defaultValue={""}
                >
                  <MenuItem value={""}>---</MenuItem>
                  {this.state.type_categories &&
                                        this.state.type_categories.map((type, key) => {
                                          return (
                                            <MenuItem
                                              key={`typeCategorieTop_${key}`}
                                              value={type.id}
                                            >
                                              {type.type}
                                            </MenuItem>
                                          )
                                        })}
                </Select>
              </FormControl>
              {this.state.TypeCategorieError && <label className="error">{this.state.TypeCategorieError}</label>}
            </Grid>
            <Grid item xs={4} md={4}>
                            Couleur :
            </Grid>
            <Grid item xs={2} md={2}>
              <input
                type="color"
                value={this.state.color}
                style={{ width: "100%", height: "calc(100% - 12px)", marginTop: "8px", marginBottom: "4px" }}
                onChange={(e) => this.changeColor(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl
                margin="dense"
                className="backgroudWhite"
                fullWidth
                size="small"
                value={this.state.color}
              >
                <TextField
                  id="outlined-basic"
                  label="Color"
                  value={this.state.color}
                  onChange={(e) => this.changeColor(e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </FormControl>
              {this.state.ColorError && <label className="error">{this.state.ColorError}</label>}
            </Grid>
            <Grid item xs={4} md={4}>
                            Commentaire obligatoire :
            </Grid>
            <Grid item xs={8} md={8}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={this.state.commentaire}
                >
                  <FormControlLabel
                    value="oui"
                    control={<Radio />}
                    label="Oui"
                    onChange={(e) => this.changeCommentaire("oui")}
                  />
                  <FormControlLabel
                    value="non"
                    control={<Radio />}
                    label="Non"
                    onChange={(e) => this.changeCommentaire("non")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Formbutton
                classname="btn btn-primary mt-3"
                textBtn="Créer"
                colorBtn="#252454"
                disabled={this.state.disabled}
                onClick={() => {
                  this.disabledBtn()
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    liste_type_categories: state.ticker.liste_type_categories,
    liste_marque: state.ticker.liste_marque,
    liste_bu: state.ticker.liste_bu,
    liste_client: state.ticker.liste_client,
    liste_lettre: state.ticker.liste_lettre,
    add_ticker: state.ticker.add_ticker,
    liste_permissions: state.user.liste_permissions,
  }
}

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    liste_type_categories_action: liste_type_categories_action,
    liste_marque_action: liste_marque_action,
    liste_bu_action: liste_bu_action,
    liste_client_action: liste_client_action,
    liste_lettre_ticker_action: liste_lettre_ticker_action,
    add_ticker_action: add_ticker_action,
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(AjouterTickerDialog)
