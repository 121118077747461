export default {
  login: "/login",
  redirection: "/redirection",
  moncompte: "/mon-compte",

  // Ticker
  dashboard: "/dashboard",
  MesTickersAlert: "/alert-mes-tickers",
  statutTicker: "/statut-ticker",
  detailTicker: "/detail-ticker",
  detailTickerSimplifie: "/detail-ticker-simplifie",
  MesTickersSuperUser: "/mes-tickers-super-user",
  MesTickers: "/mes-tickers",

  // Clients
  clients: "/clients",
  detailclient: "/detail-client",
  detailClientSimplifie: "/detail-client-simplifie",
  facture: "/facture",
  tableauBordFacturation: "/tableau-bord-facturation",
  contacts: "/contacts",
  rapportClient: "/rapport-client",

  // Marques
  marques: "/marques",
  detailmarque: "/detail-marque",
  detailMarqueSimplifie: "/detail-marque-simplifie",

  // Fournisseur
  fournisseur: "/fournisseur",
  factureFournisseur: "/facture-fournisseur",

  // Fournisseur v2
  fournisseurV2: "/fournisseur-v2",
  factureFournisseurV2: "/facture-fournisseur-v2",

  // Note de Frais
  OrdreDeMission: "/mes-ordres-de-mission",
  DemandeRemboursement: "/demandes-remboursement",
  ValidationDemandeRemboursement: "/validation-demandes-remboursement",

  // Timesheet
  timesheet: "/timesheet",
  suiviTimesheet: "/suivi-timesheet",

  // Explication
  explication: "/explication",
  MesDemandesExplication: "/mes-demandes-explication",
  ToutesDemandesExplication: "/all-demandes-explication",

  // Vie Interne
  trombinoscope: "/trombinoscope",
  EquipeDispo: "/equipe-disponible",
  mesTimeSheet: "/mes-timesheet",
  statistics: "/user",

  // Maladie
  declarationMaladie: "/nouvelle-declaration-maladie",
  Maladie: "/declarations-maladie",
  AllMaladie: "/toutes-declarations-maladie",

  // Attestation
  demandeAttestation: "/nouvelle-demande-attestation",
  Attestation: "/demandes-attestation",
  ValidationAttestation: "/validation-attestation",

  // Conge
  conge: "/demandes-conges",
  solde: "/mon-solde",
  demandeConge: "/nouvelle-demande-conge",
  teamConge: "/mon-equipe-demandes",
  teamSolde: "/mon-equipe-solde",
  allConge: "/all-demandes",

  // demandes
  demande: "/mes-demandes-attestations",
  toutesDemandesAttestation: "/toutes-demandes-attestations",
  dashboardAttestation: "/dashboard-attestation",

  // Dashboard
  dashboadAllocationFactureClient: "/dashboard-allocation-facture-client"
}



