export const initialState = {
  List_explication: [],
  List_explication_author: [],
  List_rapport_user_ticker: [],
  explication_created: [],
  reponse_created: [],
  explication_updated: [],
}
const explication = (state = initialState, action) => {
  switch (action.type) {
  case "LISTE_RAPPORT_USER_TICKER_DONE": {
    return {
      ...state,
      List_rapport_user_ticker: action.payload
    }
  }
  case "LISTE_EXPLICATION_DONE": {
    return {
      ...state,
      List_explication: action.payload
    }
  }
  case "LISTE_EXPLICATION_AUTHOR_DONE": {
    return {
      ...state,
      List_explication_author: action.payload
    }
  }
  case "CREATE_DEMANDE_EXPLICATION_DONE": {
    return {
      ...state,
      explication_created: action.payload
    }
  }
  case "UPDATE_DEMANDE_EXPLICATION_DONE": {
    return {
      ...state,
      explication_updated: action.payload
    }
  }
  case "CREATE_REPONSE_EXPLICATION_DONE": {
    return {
      ...state,
      reponse_created: action.payload
    }
  }
  default:
    return state
  }
}

export default explication
