export const initialState = {
  liste_maladie: [],
  liste_maladie_uploade: [],
  liste_maladie_valide: [],
  liste_all_maladie: [],
  liste_all_maladie_uploade: [],
  liste_all_maladie_valide: [],
  maladie_added: [],
  maladie_edited: []
}

const maladie = (state = {}, action) => {
  switch (action.type) {
  case "ADD_MALADIE_DONE":
    return {
      ...state,
      maladie_added: action.payload,
    }
  case "EDIT_MALADIE_DONE":
    return {
      ...state,
      maladie_edited: action.payload,
    }
  case "LIST_MALADIE_DONE":
    return {
      ...state,
      liste_maladie: action.payload,
    }
  case "LIST_MALADIE_UPLOADE_DONE":
    return {
      ...state,
      liste_maladie_uploade: action.payload,
    }
  case "LIST_MALADIE_VALIDE_DONE":
    return {
      ...state,
      liste_maladie_valide: action.payload,
    }
  case "LIST_ALL_MALADIE_DONE":
    return {
      ...state,
      liste_all_maladie: action.payload,
    }
  case "LIST_ALL_MALADIE_UPLOADE_DONE":
    return {
      ...state,
      liste_all_maladie_uploade: action.payload,
    }
  case "LIST_ALL_MALADIE_VALIDE_DONE":
    return {
      ...state,
      liste_all_maladie_valide: action.payload,
    }
  default:
    return state
  }
}

export default maladie
