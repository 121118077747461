import React, { Component } from "react"
import { Spinner } from "react-bootstrap"
import "./commun.css"
import PacmanLoader from "react-spinners/PacmanLoader"

const override = {
  display: "block",
  margin: "2 auto",
  borderColor: "red",
}

export default class SpinnerPage extends Component {
  render() {
    return (
      <div className='LoadingOver'>
        <div className='SpinnerPage'>
          <PacmanLoader
            color="#103f66"
            loading="true"
            cssOverride={override}
            size={25}
            css={override}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    )
  }
}
