export const initialState = {
  List_ordre_mission: [],
  List_remboursement: [],
  List_remboursement_valide: [],
  List_remboursement_rembourse: [],
  List_remboursement_rejete: [],
  Moyen_paiement: [],
  Moyen_remboursement: [],
  Categorie_remboursement: [],
  Statut_ordre: [],
  Statut_remboursement: [],
  order_created: [],
  order_updated: [],
  order_deleted: [],
  refund_created: [],
  refund_updated: [],
  refund_deleted: [],
}
const noteFrais = (state = initialState, action) => {
  switch (action.type) {
  case "LIST_ORDRES_MISSIONS_DONE": {
    return {
      ...state,
      List_ordre_mission: action.payload,
    }
  }
  case "LIST_REMBOURSEMENT_DONE": {
    return {
      ...state,
      List_remboursement: action.payload,
    }
  }
  case "LIST_REMBOURSEMENT_VALIDE_DONE": {
    return {
      ...state,
      List_remboursement_valide: action.payload,
    }
  }
  case "LIST_REMBOURSEMENT_REMBOURSE_DONE": {
    return {
      ...state,
      List_remboursement_rembourse: action.payload,
    }
  }
  case "LIST_REMBOURSEMENT_REJETE_DONE": {
    return {
      ...state,
      List_remboursement_rejete: action.payload,
    }
  }
  case "MOYEN_PAIEMNT_DONE": {
    return {
      ...state,
      Moyen_paiement: action.payload,
    }
  }
  case "MOYEN_REMBOURSEMENT_DONE": {
    return {
      ...state,
      Moyen_remboursement: action.payload,
    }
  }
  case "CATEGORIE_REMBOURSEMENT_DONE": {
    return {
      ...state,
      Categorie_remboursement: action.payload,
    }
  }
  case "STATUT_ORDRE_DONE": {
    return {
      ...state,
      Statut_ordre: action.payload,
    }
  }
  case "STATUT_REMBOURSEMENT_DONE": {
    return {
      ...state,
      Statut_remboursement: action.payload,
    }
  }
  case "CREATE_ORDER_DONE": {
    return {
      ...state,
      order_created: action.payload,
    }
  }
  case "UPDATE_ORDER_DONE": {
    return {
      ...state,
      order_updated: action.payload,
    }
  }
  case "DELETE_ORDER_DONE": {
    return {
      ...state,
      order_deleted: action.payload,
    }
  }
  case "CREATE_REFUND_DONE": {
    return {
      ...state,
      refund_created: action.payload,
    }
  }
  case "UPDATE_REFUND_DONE": {
    return {
      ...state,
      refund_updated: action.payload,
    }
  }
  case "DELETE_REFUND_DONE": {
    return {
      ...state,
      refund_deleted: action.payload,
    }
  }
  default:
    return state
  }
}

export default noteFrais
