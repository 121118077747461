// Next Day
export function next_day_action(payload) {
  return {
    type: "NEXT_DAY",
    payload,
  }
}

export function next_day_action_done(request) {
  return {
    type: "NEXT_DAY_DONE",
    payload: request,
  }
}

// Day Before
export function day_before_action(payload) {
  return {
    type: "DAY_BEFORE",
    payload,
  }
}

export function day_before_action_done(request) {
  return {
    type: "DAY_BEFORE_DONE",
    payload: request,
  }
}

// Ticker admin
export function ticker_admin_action(payload) {
  return {
    type: "TICKER_ADMIN",
    payload,
  }
}

export function ticker_admin_action_done(request) {
  return {
    type: "TICKER_ADMIN_DONE",
    payload: request,
  }
}

// Ticker saved
export function ticker_saved_action(payload) {
  return {
    type: "TICKER_SAVED",
    payload,
  }
}

export function ticker_saved_action_done(request) {
  return {
    type: "TICKER_SAVED_DONE",
    payload: request,
  }
}

// Publish timesheet
export function publish_timesheet_action(payload) {
  return {
    type: "PUBLISH_TIMESHEET",
    payload,
  }
}

export function publish_timesheet_action_done(request) {
  return {
    type: "PUBLISH_TIMESHEET_DONE",
    payload: request,
  }
}

// Publish timesheet
export function Liste_suivi_timesheet_action(payload) {
  return {
    type: "SUIVI_TIMESHEET",
    payload,
  }
}

export function Liste_suivi_timesheet_action_done(request) {
  return {
    type: "SUIVI_TIMESHEET_DONE",
    payload: request,
  }
}

// Timesheet client state
export function setSelectedTickers(payload) {
  return {
    type: "SET_SELECTED_TICKERS",
    payload,
  }
}
