export const initialState = {
  allocation_offre: [],
  liste_facture: [],
  add_facture: {},
  update_facture: {},
  entite: [],
  statut_facture: [],
  liste_facturation: [],
  revenue: [],
  liste_allocation_offre_facture: [],
  facture_ticker: [],
  statistique_facture: [],
}

const facture = (state = {}, action) => {
  switch (action.type) {
  case "ALLOCATION_OFFRE_DONE":
    return {
      ...state,
      allocation_offre: action.payload,
    }
  case "LISTE_FACTURE_DONE":
    return {
      ...state,
      liste_facture: action.payload,
    }
  case "ADD_FACTURE_DONE":
    return {
      ...state,
      add_facture: action.payload,
    }
  case "UPDATE_FACTURE_DONE":
    return {
      ...state,
      update_facture: action.payload,
    }
  case "FACTURATION_DONE":
    return {
      ...state,
      liste_facturation: action.payload,
    }
  case "ENTITE_DONE":
    return {
      ...state,
      entite: action.payload,
    }
  case "STATUT_FACTURE_DONE":
    return {
      ...state,
      statut_facture: action.payload,
    }
  case "REVENUE_LIST_DONE":
    return {
      ...state,
      revenue: action.payload,
    }
  case "ALLOCATION_OFFRE_FACTURE_DONE":
    return {
      ...state,
      liste_allocation_offre_facture: action.payload,
    }
  case "STATISTIQUE_FACTURE_DONE":
    return {
      ...state,
      statistique_facture: action.payload,
    }
  case "FACTURE_TICKER_DONE":
    return {
      ...state,
      facture_ticker: action.payload,
    }
  default:
    return state
  }
}

export default facture
