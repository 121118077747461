import { call, takeEvery } from "redux-saga/effects"
import {
  add_client_action_done,
  allocation_client_action_done,
  client_date_controller_action_done,
  client_user_stat_action_done,
  depense_client_action_done,
  facture_client_action_done,
  info_client_action_done,
  liste_clients_action_done,
  liste_contacts_action_done,
  rapport_client_action_done,
  ticker_client_action_done,
  update_client_action_done
} from "../actions/client"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, patch, post } from "../services/request"


// liste_clients
function* liste_clients_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETCLIENTS}?page=${action.payload.page}${action.payload.textRecherche && "&search=" + action.payload.textRecherche}${action.payload.typeClient && "&type_client=" + action.payload.typeClient}`,
    get,
    null,
    liste_clients_action_done
  )
}

export function* watch_liste_clients_saga() {
  yield takeEvery("LISTE_CLIENTS", liste_clients_saga)
}


// add_client
function* add_client_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETCLIENTS}`,
    post,
    action.payload,
    add_client_action_done
  )
}

export function* watch_add_clients_saga() {
  yield takeEvery("ADD_CLIENT", add_client_saga)
}


// liste_contacts
function* liste_contacts_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LISTECONTACTS}${action.payload && "?search=" + action.payload}`,
    get,
    null,
    liste_contacts_action_done
  )
}

export function* watch_liste_contacts_saga() {
  yield takeEvery("LISTE_CONTACTS", liste_contacts_saga)
}


// rapport_client
function* rapport_client_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETRAPPORTCLIENT}?${action.payload.idTicker && "&ticker=" + action.payload.idTicker}${action.payload.idMarque && "&marque=" + action.payload.idMarque}${action.payload.idClient && "&client=" + action.payload.idClient}${action.payload.dateDebut ? "&date_debut=" + action.payload.dateDebut : ""}${action.payload.dateFin ? "&date_fin=" + action.payload.dateFin : ""}`,
    get,
    null,
    rapport_client_action_done,
  )
}

export function* watch_rapport_client_saga() {
  yield takeEvery("RAPPORT_CLIENT", rapport_client_saga)
}

// deatil_client
function* detail_client_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_DETAILCLIENT}${action.payload.id}/`,
    get,
    null,
    info_client_action_done
  )
}

export function* watch_detail_client_saga() {
  yield takeEvery("INFO_CLIENT", detail_client_saga)
}

// update_client
function* update_client_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATECLIENT}${action.payload.id}/`,
    patch,
    action.payload.data,
    update_client_action_done
  )
}

export function* watch_update_client_saga() {
  yield takeEvery("UPDATE_CLIENT", update_client_saga)
}

// client_user_stat
function* client_user_stat_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETSTATSBYWEEK}?time=${action.payload.type}&date=${action.payload.date}&client=${action.payload.id}${action.payload.typeClient === "ouvert" ? "&ouvert=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.year ? "&year=" + action.payload.year : ""}${action.payload.typeClient === "ouvert" && action.payload.trimesterClient ? "&trimester=" + action.payload.trimesterClient : ""}${action.payload.typeClient === "ouvert" && action.payload.ActifFilter ? "&actif=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.PassifFilter ? "&passif=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.ArchivedFilter ? "&archive=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.typeClient === "mensuel" ? "&mensuel=True" : ""}${action.payload.displayerTeam && action.payload.displayerTeam.length > 0 ? "&team=[" + action.payload.displayerTeam + "]" : ""}`,
    get,
    null,
    client_user_stat_action_done
  )
}

export function* watch_client_user_stat_saga() {
  yield takeEvery("CLIENT_USER_STAT", client_user_stat_saga)
}


// client_date_controller
function* client_date_controller_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETSTATSCONTROLS}?time=${action.payload.type}&date=${action.payload.date}&client=${action.payload.id}`,
    get,
    null,
    client_date_controller_action_done
  )
}

export function* watch_client_date_controller_saga() {
  yield takeEvery("CLIENT_DATE_CONTROLLER", client_date_controller_saga)
}


// depense_ticker
function* depense_client_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_DEPENSES}?client=${action.payload.id}&date=${action.payload.date}${action.payload.typeClient === "ouvert" ? "&ouvert=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.year ? "&year=" + action.payload.year : ""}${action.payload.typeClient === "ouvert" && action.payload.trimesterClient ? "&trimester=" + action.payload.trimesterClient : ""}${action.payload.typeClient === "ouvert" && action.payload.ActifFilter ? "&actif=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.PassifFilter ? "&passif=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.ArchivedFilter ? "&archive=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.typeClient === "mensuel" ? "&mensuel=True" : ""}`,
    get,
    null,
    depense_client_action_done
  )
}

export function* watch_depense_client_saga() {
  yield takeEvery("DEPENSE_CLIENT", depense_client_saga)
}

// allocation_client
function* allocation_client_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LISTEALLOCATIONS}?detail=True&date=${action.payload.date}&client=${action.payload.id}${action.payload.typeClient === "ouvert" ? "&ouvert=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.year ? "&year=" + action.payload.year : ""}${action.payload.typeClient === "ouvert" && action.payload.trimesterClient ? "&trimester=" + action.payload.trimesterClient : ""}${action.payload.typeClient === "ouvert" && action.payload.ActifFilter ? "&actif=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.PassifFilter ? "&passif=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.ArchivedFilter ? "&archive=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.typeClient === "mensuel" ? "&mensuel=True" : ""}`,
    get,
    null,
    allocation_client_action_done
  )
}

export function* watch_allocation_client_saga() {
  yield takeEvery("ALLOCATION_CLIENT", allocation_client_saga)
}

// facture_client
function* facture_client_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_FACTURESCLIENT}?date=${action.payload.date}${action.payload.typeClient === "ouvert" ? "&ouvert=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.ActifFilter ? "&actif=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.PassifFilter ? "&passif=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.ArchivedFilter ? "&archive=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.typeClient === "ouvert" && action.payload.dateTicker ? "&year=" + action.payload.year : ""}${action.payload.typeClient === "ouvert" && action.payload.trimesterClient ? "&trimester=" + action.payload.trimesterClient : ""}${action.payload.typeClient === "mensuel" ? "&mensuel=True" : ""}&client=${action.payload.id}${action.payload.future ? "&future=True" : ""}${action.payload.facture ? "&facture=True" : ""}${action.payload.lead ? "&lead=True" : ""}${action.payload.abandonne ? "&abandonne=True" : ""}${action.payload.virtuelle ? "&virtuelle=True" : ""}${action.payload.afacturer ? "&en_attente=True" : ""}`,
    get,
    null,
    facture_client_action_done
  )
}

export function* watch_facture_client_saga() {
  yield takeEvery("FACTURE_CLIENT", facture_client_saga)
}

// ticker_client
function* ticker_client_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETTICKERSCLIENTS}${action.payload.idClient}/`,
    get,
    null,
    ticker_client_action_done
  )
}

export function* watch_ticker_client_saga() {
  yield takeEvery("TICKER_CLIENT", ticker_client_saga)
}

/*
//marque_client
function* marque_client_saga(action) {
	yield call(
		callApi,
		`${urls.apiUrl}${urls.URL_FACTURESCLIENT}?date=${action.payload.date}${action.payload.typeClient==='ouvert' ? '&ouvert=True' : ''}${action.payload.typeClient === "ouvert" && action.payload.ActifFilter ? "&actif=True" : "" }${action.payload.typeClient === "ouvert" && action.payload.PassifFilter ? "&passif=True" : ""}${action.payload.typeClient === "ouvert" &&action.payload.ArchivedFilter ? "&archive=True": ""}${action.payload.typeClient === "ouvert" && action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.typeClient==='ouvert' && action.payload.dateTicker ? '&year='+action.payload.year : ''}${action.payload.typeClient==='ouvert' && action.payload.trimesterClient ? '&trimester='+action.payload.trimesterClient : ''}${action.payload.typeClient==='mensuel' ? '&mensuel=True' : ''}&client=${action.payload.id}${action.payload.future ? '&future=True' : ''}${action.payload.facture ? '&facture=True' : ''}${action.payload.lead ? '&lead=True' : ''}${action.payload.abandonne ? '&abandonne=True' : ''}${action.payload.virtuelle ? '&virtuelle=True' : ''}${action.payload.afacturer ? '&en_attente=True' : ''}`,
		get,
        null,
        marque_client_action_done
	);
}
export function* watch_marque_client_saga() {
    yield takeEvery('MARQUE_CLIENT', marque_client_saga);
}*/
